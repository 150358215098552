import React, { useState } from "react";
import { Routes, Route, Outlet, useNavigate } from "react-router-dom";
import Dashboard from "./../pages/Dashboard/Dashboard";
import ConfirmEmail from "./../pages/ConfirmEmail/ConfirmEmail"
import { Navigate } from "react-router-dom";
import DownloadWeb from "../pages/DownloadWeb/DownloadWeb";
import Header from "./../Components/Layout/Header";
import axios from "axios";
import ConfirmPhone from "../pages/ConfirmPhone/ConfirmPhone";
import SetupEntity from "../pages/SetupEntity/SetupEntity";
import TestPage from "../pages/TestPages/TestPage";
import SendNDA from "../pages/SendNDA/SendNDA";
import NDA from "../pages/NDA/NDA";
import Docs from './../pages/Docs/Docs';
import { ReceiveNDA } from "../pages/ReceiveNDA/ReceiveNDA";
import PreviewNDA from "../pages/PreviewNDA/PreviewNDA";
import Settings from "../pages/Settings/Settings";
import Alerts from './../pages/Alerts/Alerts';
import DeleteData from "../pages/DeleteData/DeleteData";
import DeleteAccount from "../pages/DeleteAccount/DeleteAccount";
import EditProfile from "../pages/EditProfile/EditProfile";
import ChangePassword from "../pages/ChangePassowrd/ChangePassword";
import ChangeAvatar from './../pages/ChangeAvatar/ChangeAvatar';
import EditEntity from "../pages/EditEntity/EditEntity";
import EditYourEntity from "../pages/EditYourEntity/EditYourEntity";
import EditYourAddress from './../pages/EditYourAddress/EditYourAddress';
import EditYourSignature from './../pages/EditYourSignature/EditYourSignature';
import { ViewDocument } from './../pages/ViewDocument/ViewDocument';
import NdaTerms from "../pages/NDATerms/NdaTerms";
import TermsOfService from "../pages/TermsOfService/TermsOfService";
import Chats from "../pages/Chats/Chats";

const AppLayout = ({ setReload }) => (
  <>
    <div className="grid-container">
      <div className="item1">
        <Header setReload={setReload} />
      </div>
      <div className="item3 text-start">
        <Outlet />
      </div>
    </div>
  </>
);

const UserRoutes = () => {
  const [isReload, setReload] = useState(false);
  const navigate = useNavigate();
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        navigate("/");
      }
      return error;
    }
  );
  return (
    <>
      <Routes>
        <Route element={<AppLayout setReload={setReload} />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/docs" element={<Docs/>} />
          <Route path="/dashboard" element={<Navigate to="/" />} />
          <Route path="/nda/:Id" element={<NDA/>} />
          <Route path="/chats" element={<Chats/>} />
          <Route path="/preview-nda" element={<PreviewNDA/>}/>
          <Route path="/Settings" element={<Settings/>}/>
          <Route path="/Alerts" element={<Alerts/>}/>
          <Route path="/edit-info" element={<EditProfile/>}/>
          <Route path="/edit-password" element={<ChangePassword/>}/>
          <Route path="/edit-avatar" element={<ChangeAvatar/>}/>
          <Route path="/edit-entity" element={<EditEntity/>}/>
          <Route path="/edit-your-entity" element={<EditYourEntity/>}/>
          <Route path="/edit-your-address" element={<EditYourAddress/>}/>
          <Route path="/edit-your-signature" element={<EditYourSignature/>}/>
          <Route path="/view-document/:AccountId/:DocumentId/:isFromReceiver" element={<ViewDocument/>}/>
        </Route>
        <Route path="/verify-email/:Id" element={<ConfirmEmail/>}/>
        <Route path="/verify-phone/:Id" element={<ConfirmPhone/>}/>
        <Route path="/verify-entity/:Id" element={<SetupEntity/>}/>
        <Route path="/send-nda/:Id" element={<SendNDA/>}/>
        <Route path="/pruebas" element={<TestPage/>}/>
        <Route path="/receive-nda" element={<ReceiveNDA/>}/>
        <Route path="/delete-data" element={<DeleteData/>}/>
        <Route path="/delete-account" element={<DeleteAccount/>}/>
        <Route path='/nda-terms' element={<NdaTerms/>}/>
        <Route path="/terms-and-conditions" element={<TermsOfService />} />
      </Routes>
    </>
  );
};

export default UserRoutes;
