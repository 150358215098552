export const chatInfo = {
	chat: [
		{
			id: 1,
			sender: "Juan",
			message: "Hola, ¿cómo estás?",
			timestamp: "2024-09-29T14:30:00",
			outgoing: true,
			incoming: false,
		},
		{
			id: 2,
			sender: "Ana",
			message: "¡Hola, Juan! Estoy bien, ¿y tú?",
			timestamp: "2024-09-29T14:31:00",
			outgoing: false,
			incoming: true,
		},
		{
			id: 3,
			sender: "Juan",
			message: "Todo bien, gracias. ¿Qué planes tienes para hoy?",
			timestamp: "2024-09-29T14:32:00",
			outgoing: true,
			incoming: false,
		},
		{
			id: 4,
			sender: "Ana",
			message: "No muchos, quizá salga a caminar más tarde. ¿Y tú?",
			timestamp: "2024-09-29T14:33:00",
			outgoing: false,
			incoming: true,
		},
		{
			id: 5,
			sender: "Juan",
			message:
				"Voy a trabajar en un proyecto por un rato, pero después me relajaré.",
			timestamp: "2024-09-29T14:34:00",
			outgoing: true,
			incoming: false,
		},
		{
			id: 6,
			sender: "Ana",
			message: "Suena bien. ¡Que tengas un buen día!",
			timestamp: "2024-09-29T14:35:00",
			outgoing: false,
			incoming: true,
		},
		{
			id: 7,
			sender: "Juan",
			message: "¡Igualmente! Nos vemos luego.",
			timestamp: "2024-09-29T14:36:00",
			outgoing: true,
			incoming: false,
		},
	],
};
