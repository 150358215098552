import React from 'react'
import './ComunicationCard.css'
import contact from '../../images/profile.png'
import chat from "../../images/chat.png";
import docs from "../../images/docs.png";
import { IoChatbubbleEllipses } from "react-icons/io5";

const ComunicationCard = ({ contactImg, fullName, onClick }) => {
    return (
        <div className='col-12 col-lg-4 col-md-12 m-2' style={{ maxWidth: "480px", minWidth: "400px", cursor: "pointer" }} onClick={onClick}>
            <div className='card-container'>
                <div className='image-container w-25'>
                    <div className='chat-img'>
                        <img src={contactImg ? contactImg : contact} alt="contact-img" />
                    </div>
                </div>
                <div className='info-chat w-50'>
                    <div className="chat-user">{fullName ? fullName : "Firts Name Last Name"}</div>
                    <div className='chat-info-notifications'>
                        <div className='d-flex justify-content-center align-items-center gap-2'>
                            <img src={chat} alt="chat-img" />
                            <span>15</span>
                        </div>
                        <div className='d-flex justify-content-center align-items-center gap-2'>
                            <img src={docs} alt="chat-img" />
                            <span>10</span>
                        </div>
                    </div>
                </div>
                <div className='time-chat w-25'>
                    <div className='time-text'>10:25 AM</div>
                    <button type="button" className="position-relative btn btn-link p-0 text-white rounded-pill">
                        <IoChatbubbleEllipses size={35} color="#0572D2" />
                        <span className="position-absolute top-0 start-100 translate-middle p-2 bg-danger border border-light rounded-circle">
                            <span className="visually-hidden">New alerts</span>
                        </span>
                    </button>
                </div>
                <div></div>
            </div>
        </div>
    )
}

export default ComunicationCard