import { settings } from "firebase/analytics";

export const dataInfo = {
	en: [
		{
			id: 1,
			title: "nav-menu",
			description1: "PRIVACY POLICY",
			description2: "TERMS AND CONDITIONS",
			description3: "DISCLAIMER",
			description4: "WAIT LIST",
			description5: "LOGIN",
			welcomeTitle1: "Welcome to Confidential Bee:",
			welcomeTitle2: "Your Gateway to Secure Document",
			welcomeTitle3: "Management",
			welcomeText1:
				"ConfidentialBee is your ultimate solution for secure document management. Whether you're a professional, a business owner, or an individual seeking to safeguard your sensitive information, ConfidentialBee has you covered. Our platform offers advanced encryption technology, seamless document sharing, and digital signature capabilities, all in one easy-to-use interface.",
			welcomeText2:
				"Join our community today to experience the peace of mind that comes with knowing your confidential documents are protected. Whether you're on the go or working from home, ConfidentialBee ensures that your privacy remains intact.",
			welcomeButton: "Download the app now!",
			storeImages: "Download the app now!",
		},
		{
			id: 2,
			title: "Be the first to Benefit for",
			title1: "the First time from a Simple",
			title2: "Solution to a Difficult",
			title3: "Problem",
			text: "Please enter your information and join our list",
			text2: "to be among the first to receive our",
			text3: "application:",
			subtext1: "Send NDA's Fast and Easy.",
			subtext2:
				"No More Figuring Out how to get an NDA document sent to a",
			subtext3: "Use Our Standard Format NDA or your Own.",
			footerText:
				"Be one of the first 1000 in the wait list and you will get Confidential Bee (Basic) for Free!",
		},
		{
			firstname: "First Name",
			lastname: "Last Name",
			email: "Email",
			phoneNumber: "Phone Number",
			submit: "Submit",
			password: "Password",
			forgotPassword: "Forgot Password?",
			sendMeOtp: "Send me Otp",
			completeRegistration: "Complete registration",
			username: "Username",
			confirmPassword: "Confirm password",
			continue: "Continue",
			editYourAvatar: "Edit your avatar",
			home: "Home",
			docs: "Docs",
			alerts: "Alerts",
			settings: "Settings",
			chats:"Chats",
			search: "Search",
			resendNDA: "Resend NDA",
			currentPassword: "Current Password",
			newPassword: "New Password",
			update: "Update",
			close: "Close",
			saveChanges: "Save changes",
		},
		{
			welcomeTitle: "Welcome to ConfidentialBee!",
			text1: "To access your account and keep your documents secure, please click 'Log In' on the right.",
			text2: "If you're new here, click on 'Sign Up' to join our community of secure document managers.",
			terms: "Terms of Service",
			privacy: "Privacy Policy",
			login: "Log In",
			signup: "Sign Up",
			welcome: "Welcome",
			welcomeSubtitle: "Click the 'Login' button below to",
			welcomeSubtitle2: "sign in with your email",
			socialLogin: "Log in using your preferred social media acccount",
			socialRegister:
				"Create your account using your preferred social media plataform",
			registration: "Ready to join us?",
			completeRegistration: "Complete registration",
			next: "Next",
			signupTitle: "Password",
			passwordError1: "Not contain the users account name.",
			passwordError2:
				"Exceeded six characters in length regardless of the minimum",
			passwordError3: "Contains at least one character in Upper Case.",
			passwordError4: "Contains at least one numeric character.",
			passwordError5:
				"Contains at least one character of the following Symbols: !@#$%^&*().",
			editProfile: "Edit Profile",
			logout: "Logout",
			verifyTitle: "Verify and Setup",
			verifyEmail: "Verify your Email!",
			verifyEmail2: "Please verify your Email",
			confirmEmail: "Confirm Email",
			verifyPhone: "Verify your Phone!",
			verifyPhone2: "Please verify your Phone",
			confirmPhone: "Confirm Phone",
			verifyEntity: "Setup your Entity",
			verifyEntity2: "Please Setup your Entity",
			setupButton: "Setup",
			skip: "Skip for Now",
			emailValidationTitle: "Email validation",
			emailValidationContent: "Enter the validation code texted to you",
			validationButton: "Verify",
			validationButton2: "Re-Send",
			validationTitle1: "Your email is now",
			validationTitle2: "verifield!",
			validationWarning: "Oops wrong code",
			validationWarning2: "The validation code didn't work.",
			validationWarning3: "We'll text you a new one",
			tryAgain: "Try Again",
			modalMessage: "We just sent you a verification code,",
			modalMessage2: "please check your email.",
			notification: "Notification",
			phoneValidationTitle: "Phone validation",
			phoneValidationContent: "Enter the validation code texted to you",
			validationButton3: "Verify",
			validationButton4: "Re-Send",
			validationTitle3: "Your phone is now",
			validationTitle4: "verifield!",
			validationWarning4: "Oops wrong code",
			validationWarning5: "The validation code didn't work.",
			validationWarning6: "We'll text you a new one",
			tryAgain2: "Try Again",
			modalMessage3: "We just sent you a text message,",
			modalMessage4: "please check your phone.",
			address: "Address",
			review: "Review",
			sign: "Sign",
			createTitle: "Create your Entity!",
			entityName: "Entity Name",
			entityName2: "name of entry",
			yourAddress: "Your Address",
			suite: "Suite/Apt",
			state: "State",
			zipCode: "ZipCode",
			cancelButton: "Cancel",
			previewNda: "Preview NDA",
			signature: "Signature",
			drawSignature: "Draw Signature",
			typeSignature: "Type Signature",
			signatureMessage: "Draw your signature below",
			clearButton: "Clear",
			enterSignature: "Enter your signature",
			sendNda: "Send NDA",
			ndaText:
				"Work with our standard NDA and send it to business partners for quick turnaround!",
			useNda: "Want to use",
			useNda2: "Your Own NDA?",
			bill1: "Let Us Handle",
			bill2: "Your Bills!",
			softsmart: "We are expert mobile app deveolpers",
			softsmart2: "Click Here for a Quote!",
			suport: "Need Help with Contracts?",
			suport2: "Our Legal Department can Assist!",
			modalDashboard: " Before sending an NDA,",
			modalDashboard2: "please set up your signature.",
			modalDashboard3:
				"To request your own document, click here to notify our Customer Service department and someone will reach out to you shortly!",
			modalDashboard4:
				" If you need assistance with creating custom contracts, click here to get in touch with our Legal Department. They will provide the support you need to ensure your contracts meet all your requirements.",
			note: "Note",
			textNote:
				"You acknowledge that the provided NDA document meets your",
			textNote2: "standards for an NDA document.",
			agreeButton: "I Agree to Terms & Conditions",
			checkboxText: "By selecting the checkbox, you agree to the",
			checkboxText2: "Terms and Conditions",
			checkboxText3:
				"each time you send an NDA, and this screen will no longer bee displayed.",
			ndaRecipient: "Enter your NDA Recipient",
			ndaRecipient2: "Recipient Name",
			ndaEmail: "Email",
			or: "Or",
			sendViaPhone: "Send via phone",
			sendViaEmail: "Send via Email",
			ndaPhone: "Phone Number",
			send: "Send",
			contactList: "Contact List",
			myContacts: "My Contacts",
			yourEntity: "Your Entity!",
			yourEntity2: "Your Entity",
			recipient: "Recipient",
			all: "All",
			incoming: "Incoming",
			outgoing: "Outgoing",
			executed: "Executed",
			sent: "Sent",
			received: "Received",
			read: "Read",
			signToComplete: "Sign to Complete",
			alerts: "Alerts",
			new: "New",
			readAlert: "Read",
			noNew: "No new notifications",
			noRead: "No read notifications",
			settings: "Settings",
			accountSettings: "Account Settings",
			preferences: "Preferences",
			socialmedia: "Social Media",
			desactive: "Deactive Account",
			editPassword: "Edit Password",
			editAvatar: "Edit Avatar",
			setupEntity: "Setup your Entity!",
			notifications: "Notifications",
			selectLenguaje: "Select lenguage",
			english: "English",
			spanish: "Spanish",
			facebookAccount: "Facebook Account",
			googleAccount: "Google Account",
			linkedinAccount: "Linkedin Account",
			appleAccount: "Apple Account",
			deleteAccount: "Delete Account",
			settingsModal1:
				" To receive notifications, please ensure that notifications",
			settingsModal2: "are enabled in your browser settings.",
			settingsModal3: "To disable notifications, please desactivate ",
			settingsModal4: "them in your browser settings.",
			firstAndLast: "Profile First Name and Last Name",
			editYourEntity: "Edit your entity",
			documents: "Documents",
			yourSignature: "Your Signature",
			documentReady: "Your Document is Ready.",
			documentReady2: "Click below to Review and Sign!",
			submitAndDownload: "Submit and Download",
			downloadMessage1:
				" This number is already associated with a registered account.",
			downloadMessage2: "Click below to Review and Log in!",
			openNDA: "Open The App to See the NDA.",
			downloadMessage3: " Your Document was not found,",
			downloadMessage4: "Click below to Review and Sign!",
			downloadMessage5: " Your Document was already read,",
			downloadMessage6: "Click below to Review and Sign!",
			requestCustom: "Request Custom NDA",
			helpWithContracts: "Help with Contracts",
			canvasText1:
				"Here, you'll find the latest updates and announcements about ConfidentialBee! Stay informed and never miss out on new features.",
			canvasText2:
				"Connect with us on social media! Follow us for news, updates, and more. Look for us on your favorite platforms!",
			canvasText3:
				"Check out our FAQ section for answers to common questions and helpful tips on using ConfidentialBee effectively.",
			canvasText4: "Get the most out of your experience with us!",
			canvasText5: "Dive into a world of knowledge with ConfidentialBee!",
			removeTitle: "We're sorry to see you go!",
			removeText1:
				"If you wish to remove your account from ConfidentialBee,",
			removeText2: "please follow the steps below:",
			logIn: "Log in:",
			logInText:
				"Log in to your ConfidentialBee account using your username and password.",
			accessAccountSettings: "Access Account Settings:",
			accessAccountSettingsText:
				"Once logged in, navigate to your account settings. This can usually be found by clicking on your profile icon or name.",
			findAccountRemovalOption: "Find Account Removal Option:",
			findAccountRemovalOptionText:
				'Look for the option to remove or delete your account. This may be labeled as "Remove Account," "Delete Account," or something similar.',
			followPrompts: "Follow Prompts:",
			followPromptsText:
				"Follow the prompts or instructions provided to initiate the account removal process. You may be asked to confirm your decision or provide a reason for removing your account.",
			confirmation: "Confirmation:",
			confirmationText:
				"Once you have completed the steps to remove your account, you should receive a confirmation message indicating that your account has been successfully removed.",
			pleaseNote:
				"Please note that removing your account is irreversible and will result in the permanent deletion of all account data, including any saved documents or information associated with your account.",
			ifYouHaveQuestions:
				"If you have any questions or encounter any issues while attempting to remove your account, please contact our support team at",
			ifYouHaveQuestionsText: "info@softsmart.com",
			forAssistance: "for assistance.",
			thankYou: "Thank you for using ConfidentialBee.",
			editYourEntityTitle: "Edit your Entity!",
			nameEntry: "Name of Entry",
			oficialName: "Enter the official name of your entity:",
			forgotPassword: "Forgot Password?",
			SendVerification: "We send you a verification code by phone.",
			forgotMessage: "We just sent you a text message,",
			forgotMessage2: "please check your phone.",
			passwordMessage1: "Password must contain at least",
			passwordMessage2: "6 characters including",
			passwordMessage3: "1 symbol and 1 number",
			newPassword: "Enter your new password",
			newPassword2: "New Password",
      deleteAccounttitle: "Delete Account?",
      deleteAccountText: "We're sorry to see you go. Are you sure you want to cancel your account?",
      deleteAccountText2: "Please note that this action will result in the permanent loss of all your data.",
      no: "No",
      yes: "Yes",
	  accept1:"I accept the",
	  accept2:"terms and conditions of the NDA",
	  communications:"Communications",
	  profile:"Profile"
		},
		{
			left: "Left",
			justify: "Justify",
			right: "Right",
			termsnda: "Terms of NDA",
			text1: " In order to pursue the mutual business between the parties and/or their respective aﬃliates (the “Transaction”), both Disclosing Party and the Receiving Party recognize that there is a need to disclose to one another certain information in respect of itself and/or its aﬃliates. All such information, delivered by or on behalf of Disclosing Party and/or its Representatives (as deﬁned below) to the “Receiving Party” and/or its Representatives, whether furnished before or after the date of this Agreement and regardless of the manner in which it is furnished, together with all analyses, compilations, studies or other documents or records prepared by the Receiving Party and/or its Representatives, to the extent such analyses, compilations, studies, documents or records contain, otherwise reﬂect, or are generated from such information, is referred to herein as “Conﬁdential Information.” In consideration of the opportunity to consider such Conﬁdential Information, both parties hereby agree as follows:",
			text2: "Conﬁdential Information will be used by the Receiving Party solely for the purpose of evaluating the Transaction. Conﬁdential Information will be kept strictly conﬁdential by the Receiving Party, except that Conﬁdential Information or any portion thereof may be disclosed to aﬃliates, directors, oﬃcers, employees, advisors, attorneys, agents, controlling persons, ﬁnancing sources or other Representatives (each, a “Representative”, and collectively, the “Representatives”) of the Receiving Party who need to know such Conﬁdential Information for the purpose of evaluating the Transaction and who agree to treat such Conﬁdential Information in accordance with the terms of this Agreement.",
			text3: "The term “Conﬁdential Information” does not include information which (i) is or becomes generally available to the public other than as a result of the breach of the terms of this Agreement by the Receiving Party and/or any of its Representatives, (ii) is or has been independently acquired or developed by the Receiving Party and/or any of its Representatives without violating any of the terms of this Agreement, (iii) was within the Receiving Party’s and/or any of its Representatives’ possession prior to it being furnished to the Receiving Party and/or any of its Representatives by or on behalf of the Disclosing Party pursuant to the terms hereof or (iv) is received from a source other than the Disclosing Party and/or any of its Representatives; provided that, in the case of (iii) and (iv) above, the source of such information was not known by the Receiving Party to be bound by a conﬁdentiality obligation to the Disclosing Party or any other party with respect to such information.",
			text4: "In the event that the Receiving Party or any of its Representatives receives demand or request to disclose all or any part of the Disclosing Party’s Conﬁdential Information under the terms of a subpoena or order issued by a court of competent jurisdiction or under a civil investigative demand or similar process, (i) if legally permissible, the Receiving Party agrees to promptly notify the Disclosing Party of the existence, terms and circumstances surrounding such a demand or request and (ii) if the Receiving Party or its applicable Representative is in the opinion of its counsel compelled to disclose all or a portion of the Disclosing Party’s Conﬁdential Information, the Receiving Party or its applicable Representative may disclose that Conﬁdential Information that its counsel advises that it is compelled to disclose and will exercise reasonable eﬀorts to obtain assurance that conﬁdential treatment will be accorded to the Conﬁdential Information that is being so disclosed.",
			text5: "Unless otherwise required by law, or unless otherwise provided in a ﬁnal deﬁnitive agreement regarding the Transaction when, as and if executed, both parties and their respective Representatives will not, without the prior written consent of the other party, disclose to any person (other than Representatives of the parties hereto who need to know such information for the purpose of evaluating the Transaction and who agree to treat such information in accordance with the terms of this Agreement): (i) that discussions or negotiations are taking place concerning the Transaction, or (ii) any of the terms or conditions of the Transaction. Neither this Agreement nor the disclosure or receipt of Conﬁdential Information shall be construed as creating any obligation of a party to furnish Conﬁdential Information to the other party or to enter into any agreement or relationship with the other party.",
			text6: "Nothing in this Agreement shall divest the Disclosing Party of any of its right, title or interest in or to any of its Conﬁdential Information. Within ten (10) days after receiving a request by the Disclosing Party for the destruction of Conﬁdential Information, the Receiving Party and its Representatives shall destroy all Conﬁdential Information furnished to the Receiving Party and/or any of its Representatives by or on behalf of the Disclosing Party.",
			text7: " Notwithstanding the foregoing, the Receiving Party may retain the Conﬁdential Information, provided that such Conﬁdential Information is retained subject to the conﬁdentiality provisions contained herein for so long as it is retained by the Receiving Party, irrespective of the term of this Agreement, where and to the extent the Conﬁdential Information is: (a) retained in back-up systems in accordance with its automated security and/or disaster recovery procedures as in eﬀect from time to time; (b) incorporated in a limited manner into analyses or documents prepared by the Receiving Party; or (c) retained by counsel for regulatory compliance, or for use in pursuing, defending, or resolving a dispute under this Agreement.",
			text8: "The Receiving Party acknowledges and agrees that neither the Disclosing Party nor any of its Representatives is making any representation or warranty as to the accuracy or completeness of any of the information furnished hereunder to the Receiving Party or any of its Representatives and each of the Receiving Party and the Disclosing Party further acknowledges and agrees that no party has any obligation to the other party or any of its Representatives to authorize or pursue with the other party the Transaction.",
			text9: "Both partiesagree that money damages may not be a suﬃcient remedy for any breach of the terms of this Agreement by the Receiving Party or any of its Representatives, and that, in addition to all other remedies at law or in equity to which the Disclosing Party may be entitled, the Disclosing Party may be entitled to seek speciﬁc performance and injunctive or other equitable relief as a remedy for any such breach.",
			text10: "If it is found in a ﬁnal judgment by a court of competent jurisdiction (not subject to further appeal) that any term or provision hereof is invalid or unenforceable, it will be ineﬀective only to the extent of the invalidity, so that the remainder of the provision and Agreement will continue in full force and eﬀect.",
			text11: "This Agreement shall not be construed to limit the Disclosing Party’s, the Receiving Party’s, or any of their respective Representatives’ right to independently develop or acquire products, services, or technology without use of the other party's Conﬁdential Information.",
			text12: "The validity and interpretation of this Agreement shall be governed by, and construed and enforced in accordance with, the laws of the State of North Carolina applicable to agreements made and to be fully performed therein (without regard to its rules with respect to conﬂicts of law).",
			text13: "Neither party hereto shall assign in whole or in part its rights or obligations under this Agreement without the express written consent of the other party. This Agreement shall be binding upon and shall inure to the beneﬁt of each party’s successors and permitted assigns.",
			text14: "This Agreement represents the parties’ entire understanding regarding the subject matter and with respect to the subject matter, supersedes all written or oral agreements previously made by or on behalf of the parties. This Agreement may not be modiﬁed except by a written agreement signed by both parties.",
			text15: "For the convenience of the parties, any number of counterparts of this Agreement may be executed by the parties hereto. Each such counterpart shall be, and shall be deemed to be, an original instrument, and all such counterparts taken together shall constitute one and the same agreement.",
			text16: "The term of this Agreement shall be three (3) years from the date hereof",
			text17: "IN WITNESS WHEREOF, this Agreement has been duly executed on thedate ﬁrst written above.",
		},
		{
			warningEmail: "The device email was not found",
			warningDevice: "The device id was not found",
			warningRequireUsername: "Username and Password are required fields",
			warningConfirmPassword: "Confirm password field is required",
			warningConfirmPassword2: "Confirm password does not match",
			successRegister: "successfully registered user",
			successAvatar: "Your avatar was updated successfully!",
			warningFistname: "The firstname field is required",
			warningLastname: "The lastname field is required",
			warningPhone: "The phone number field is required",
			warningEmail2: "The email field is required",
			warningPassword: "The password field is required",
			successStepper: "The entity was successfully registered",
			warningFile: "Please select a file before continuing",
			successUpdate: "Update Successfully!",
			warningUser: "User not found",
			successVerify: "Your Email is already verify!",
			successVerify2: "Your phone is already verify!",
			successCustom: "Your custom NDA was successfully requested.",
			warningSetUp: "Please setup your entity first",
			warningRequestEmail: "Please enter your email address to continue",
			warningEmailAndPhone: "Email and phone number are required fields",
			successRegistered: "Registration successful!",
			successProfile: "Your profile has been updated successfully",
			successAddress: "Your address has been updated successfully",
			warningNoData: "No data available",
			successEntity: "Your entity has been updated successfully",
			successSignature: "Your signature has been updated successfully",
			warningTwoFields: "The two fields must be entered",
			successUpdatedPassword: "Paswsword Update Successfully!",
			warningEntityAndSignature:
				"You must have an entity and signature, please setup first!",
			successDocument: "Your document has already been sent!",
			warningUsername: "Username is required",
			warningPhoneOrEmail: "Phone number or email field is required",
			successNDA: "The NDA was sent successfully!",
			sendAgain:"Send again",
			sendAgainText:"Do you want to send again this document?",
			ndaCopy:"Document Copy",
			ndaCopyText:"This document is already signed, do you want to receive a copy in your email?",
			signed:"Congratulations!",
			signedText:"Your document has been signed successfully!",
			signedText2:"You will receive a copy of the document in your email.",
		},
		{
			termsTitle: "Terms of Service",
			efecitveDate: "Effective Date: 04/04/24",
			termsText1:
				'Welcome to ConfidentialBee! These Terms of Service ("Terms") govern your use of the website',
			termsEmail: "www.confidentialbee.com",
			termsText2:
				'("Site") and the mobile application ("App") provided by ConfidentialBee. By accessing or using the Site or App, you agree to be bound by these Terms. If you disagree with any part of the Terms, you may not access the Site or App.',
			termsUseOfSite: "Use of the Site and App:",
			termsUseOfSiteText:
				"By using the Site or App, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this Site or App.",
			termsUserAccount: "User Account:",
			termsUserAccountText:
				"To access certain features of the Site or App, you may be required to create an account. You are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer or mobile device. You agree to accept responsibility for all activities that occur under your account or password.",
			termsProhibitedActivities: "Intellectual Property:",
			termsProhibitedActivitiesText:
				"The Site and App and their original content, features, and functionality are owned by ConfidentialBee and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.",
			termsProhibited: "Prohibited Activities:",
			termsProhibitedText:
				"You agree not to use the Site or App for any unlawful purpose or to violate any laws in your jurisdiction. You also agree not to:",
			termsProhibitedText2:
				"Post or transmit any content that is defamatory, obscene, pornographic, abusive, offensive, or otherwise violates any law or right of any third party.",
			termsProhibitedText3:
				"Interfere with or disrupt the Site or App or servers or networks connected to the Site or App.",
			termsProhibitedText4:
				"Attempt to gain unauthorized access to any portion of the Site or App or any other systems or networks connected to the Site or App.",
			termsLimitation: "Limitation of Liability:",
			termsLimitationText:
				"In no event shall ConfidentialBee, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Site or App; (ii) any conduct or content of any third party on the Site or App; (iii) any content obtained from the Site or App; and (iv) unauthorized access, use, or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence), or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.",
			termsIndemnification: "Indemnification:",
			termsIndemnificationText:
				"You agree to indemnify and hold ConfidentialBee and its affiliates, officers, directors, agents, and employees harmless from any claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of your breach of these Terms or the documents they incorporate by reference, or your violation of any law or the rights of a third party.",
			termsGoverning: "Governing Law:",
			termsGoverningText:
				"These Terms shall be governed and construed in accordance with the laws of [insert jurisdiction], without regard to its conflict of law provisions.",
			termsChanges: "Changes to Terms:",
			termsChangesText:
				"ConfidentialBee reserves the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will try to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.",
			termsClosing: "Closing Statement:",
			termsClosingText:
				"Thank you for reviewing our Terms of Service. By accessing or using the ConfidentialBee website or mobile application, you agree to abide by these Terms. If you have any questions or concerns about these Terms, please contact us at info@softsmart.com. Your continued use of the Site or App indicates your acceptance of these Terms.",
		},
		{
			privacyTitle: "Privacy Policy",
			privacyEffectiveDate: "Effective Date: 04/08/24",
			privacyText1:
				'Welcome to ConfidentialBee! This Privacy Policy outlines how we collect, use, maintain, and disclose information obtained from users ("Users") of our website ',
			privacyText2:
				'and our mobile application ("App"). This policy applies to all products and services offered by ConfidentialBee.',
			privacyInformationCollection: "Information Collection:",
			privacyInformationCollectionText:
				"Personal Information: We may collect personal identification information from Users in various ways, including when they visit our site, register on the site, subscribe to our newsletter, respond to a survey, fill out a form, and in connection with other activities, services, features, or resources we make available on our Site or App. Users may be asked for, as appropriate, name, email address, mailing address, phone number, and other relevant details.",
			privacyHowWeUseCollectedInformation:
				"How We Use Collected Information:",
			privacyHowWeUseCollectedInformationText:
				"We may use the information Users provide for various purposes, including:",
			privacyHowWeUseCollectedInformationText2:
				"To personalize user experience",
			privacyHowWeUseCollectedInformationText3:
				"To improve our Site and App",
			privacyHowWeUseCollectedInformationText4:
				"To send periodic emails or push notifications",
			privacyHowWeUseCollectedInformationText5: "To process transactions",
			privacyHowWeUseCollectedInformationText6:
				"To respond to inquiries, questions, and/or other requests",
			privacyInformationProtection: "Information Protection:",
			privacyInformationProtectionText:
				"We adopt appropriate data collection, storage, and processing practices and security measures to protect against unauthorized access, alteration, disclosure, or destruction of Users' personal information, username, password, transaction information, and data stored on our Site or App.",
			privacySharingYourPersonalInformation:
				"Sharing Your Personal Information:",
			privacySharingYourPersonalInformationText:
				"We do not sell, trade, or rent Users' personal identification information to others. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates, and advertisers.",
			privacyThirdPartyWebsites: "Third-Party Websites:",
			privacyThirdPartyWebsitesText:
				"Users may find advertising or other content on our Site and App that links to the sites and services of our partners, suppliers, advertisers. We do not control the content or links that appear on these sites and are not responsible for the practices employed by websites linked to or from our Site or App.",
			privacyChangesToThisPrivacyPolicy:
				"Changes to This Privacy Policy:",
			privacyChangesToThisPrivacyPolicyText:
				"ConfidentialBee has the discretion to update this privacy policy at any time. When we do, we will revise the updated date at the bottom of this page. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.",
			privacyYourAcceptanceOfTheseTerms:
				"Your Acceptance of These Terms:",
			privacyYourAcceptanceOfTheseTermsText:
				"By using this Site or App, you signify your acceptance of this policy.",
			termsClosing: "Closing Statement:",
			termsClosingText:
				"We are committed to maintaining the privacy and security of your personal information. By using ConfidentialBee, you agree to the terms outlined in this Privacy Policy. If you have any questions or concerns about our practices or this policy, please contact us at info@softsmart.com Thank you for entrusting us with your information.",
		},
	],
	es: [
		{
			id: 1,
			title: "nav-menu",
			description1: "POLÍTICA DE PRIVACIDAD",
			description2: "TÉRMINOS Y CONDICIONES",
			description3: "AVISO",
			description4: "LISTA DE ESPERA",
			description5: "INICIAR SESIÓN",
			welcomeTitle1: "Bienvenido a Confidential Bee:",
			welcomeTitle2: "Tu ingreso a la Gestión Segura de",
			welcomeTitle3: "Documentos",
			welcomeText1:
				"ConfidentialBee es tu solución definitiva para la gestión segura de documentos. Ya seas un profesional, un propietario de negocio o una persona que busca proteger su información sensible, ConfidentialBee te tiene cubierto. Nuestra plataforma ofrece tecnología avanzada de cifrado, intercambio de documentos sin problemas y capacidades de firma digital, todo en una interfaz fácil de usar.",
			welcomeText2:
				"Únete a nuestra comunidad hoy para experimentar la tranquilidad que viene con saber que tus documentos confidenciales están protegidos. Ya sea que estés en movimiento o trabajando desde casa, ConfidentialBee asegura que tu privacidad se mantenga intacta.",
			welcomeButton: "¡Descarga la aplicación ahora!",
			storeImages: "¡Descarga la aplicación ahora!",
		},
		{
			id: 2,
			title: "Sé el primero en beneficiarte",
			title1: "por este medio, dando una",
			title2: "solución simple a un",
			title3: "problema difícil",
			text: "Por favor, ingresa tu información y únete a nuestra lista",
			text2: "para ser uno de los primeros en recibir nuestra",
			text3: "aplicación:",
			subtext1: "Envía NDA's de forma rápida y sencilla.",
			subtext2: "No más complicaciones para enviar un documento de NDA",
			subtext3: "Usa nuestro formato estándar de NDA o el tuyo propio.",
			footerText:
				"¡Sé uno de los primeros 1000 en la lista de espera y obtendrás Confidential Bee (Básico) gratis!",
		},
		{
			firstname: "Nombre",
			lastname: "Apellido",
			email: "Correo Electónico",
			phoneNumber: "Número de teléfono",
			submit: "Enviar",
			password: "Contraseña",
			forgotPassword: "¿Olvidaste tu contraseña?",
			sendMeOtp: "Enviarme Otp",
			completeRegistration: "Completar registro",
			username: "Nombre de usuario",
			confirmPassword: "Confirmar contraseña",
			continue: "Continuar",
			editYourAvatar: "Edita tu avatar",
			home: "Inicio",
			docs: "Documentos",
			alerts: "Alertas",
			settings: "Configuraciones",
			chats:"Chats",
			search: "Buscar",
			currentPassword: "Contraseña actual",
			newPassword: "Nueva contraseña",
			update: "Actualizar",
			close: "Cerrar",
			saveChanges: "Guardar cambios",
		},
		{
			welcomeTitle: "Bienvenido a ConfidentialBee!",
			text1: "Para acceder a tu cuenta y mantener tus documentos seguros, por favor haz clic en 'Iniciar Sesión' a la derecha.",
			text2: "Si eres nuevo aquí, haz clic en 'Registrarse' para unirte a nuestra comunidad de gestores de documentos seguros.",
			terms: "Términos y Condiciones",
			privacy: "Política de Privacidad",
			login: "Iniciar sesión",
			signup: "Registrate",
			welcome: "Bienvenido",
			welcomeSubtitle: "Haz clic en el botón de abajo",
			welcomeSubtitle2: "para iniciar sesión con tu ",
			socialLogin:
				"Inicia sesión usando tu cuenta de redes sociales preferida",
			socialRegister:
				"Crea tu cuenta usando tu plataforma de redes sociales preferida.",
			registration: "¿Listo para unirte a nosotros?",
			completeRegistration: "Completa  el registro",
			next: "Siguiente",
			signupTitle: "Contraseña",
			passwordError1: "No contiene el nombre de usuario de la cuenta.",
			passwordError2:
				"Excedede los seis caracteres de longitud independientemente de la longitud mínima.",
			passwordError3: "Contiene al menos un carácter en mayúscula.",
			passwordError4: "Contiene al menos un carácter numérico.",
			passwordError5:
				"Contiene al menos un carácter de los siguientes símbolos: !@#$%^&*().",
			editProfile: "Editar Perfil",
			logout: "Cerrar Sesión",
			verifyTitle: "Verificar y Configurar",
			verifyEmail: "Verifica tu Correo Electrónico!",
			verifyEmail2: "Por favor, verifica tu correo electrónico",
			confirmEmail: "Confirmar Correo",
			verifyPhone: "Verifica tu Teléfono!",
			verifyPhone2: "Por favor, verifica tu teléfono",
			confirmPhone: "Confirmar Teléfono",
			verifyEntity: "Configura tu Entidad",
			verifyEntity2: "Por favor, verifica tu entidad",
			setupButton: "Configurar",
			skip: "Omitir por ahora",
			emailValidationTitle: "Validación de correo electrónico",
			emailValidationContent:
				"Introduce el código que hemos enviado a tu correo electrónico",
			validationButton: "Verificar",
			validationButton2: "Reenviar",
			validationTitle1: "Tu correo electrónico",
			validationTitle2: "está verificado!",
			validationWarning: "Código incorrecto",
			validationWarning2: "El código de verificación no funcionó.",
			validationWarning3: "Te enviaremos un nuevo código",
			tryAgain: "Intentar de nuevo",
			modalMessage: "Acabamos de enviarte un código de verificación,",
			modalMessage2: "por favor, comprueba tu correo electrónico.",
			notification: "Notificación",
			phoneValidationTitle: "Validación de teléfono",
			phoneValidationContent:
				"Introduce el código que hemos enviado a tu teléfono",
			validationButton3: "Verificar",
			validationButton4: "Reenviar",
			validationTitle3: "Tu teléfono",
			validationTitle4: "está verificado!",
			validationWarning4: "Código incorrecto",
			validationWarning5: "El código de verificación no funcionó.",
			validationWarning6: "Te enviaremos un nuevo código",
			tryAgain2: "Intentar de nuevo",
			modalMessage3: "Acabamos de enviarte un mensaje de texto,",
			modalMessage4: "por favor, comprueba tu teléfono.",
			address: "Dirección",
			review: "Revisar",
			sign: "Firmar",
			createTitle: "Crea tu Entidad!",
			entityName: "Nombre de la Entidad",
			entityName2: "Nombre de la entidad",
			yourAddress: "Tu Dirección",
			suite: "Habitación/Departamento",
			state: "Estado",
			zipCode: "ZipCode",
			cancelButton: "Cancelar",
			previewNda: "Vista Previa del NDA",
			signature: "Firma",
			drawSignature: "Dibujar Firma",
			typeSignature: "Escribe tu Firma",
			signatureMessage: "Dibuja tu firma abajo",
			clearButton: "Borrar",
			enterSignature: "Ingresa tu firma",
			sendNda: "Enviar NDA",
			ndaText:
				"¡Trabaje con nuestro NDA estándar y envíelo a socios para una respuesta rápida!",
			useNda: "¿Quiere usar ",
			useNda2: "su propio NDA?",
			bill1: "¡Deje que nos encarguemos",
			bill2: "de sus facturas!",
			softsmart:
				"Somos desarrolladores expertos en aplicaciones móviles.",
			softsmart2: "¡Haga clic aquí para obtener una cotización!",
			suport: "¿Necesita ayuda con los contratos?",
			suport2: "¡Nuestro departamento legal puede asistirle!",
			modalDashboard: "Antes de enviar un NDA,",
			modalDashboard2: "por favor, configure su firma.",
			modalDashboard3:
				"Para solicitar su propio NDA, haga clic aquí para notificar al personal de atención al cliente y alguien lo contactará en breve!",
			modalDashboard4:
				"Si necesita ayuda con la creación de contratos personalizados, haga clic aquí para ponerse en contacto con nuestro departamento legal. Ellos proporcionarán el apoyo que necesita para asegurarse de que sus contratos cumplan con todas sus necesidades.",
			note: "Nota",
			textNote:
				"Usted reconoce que el documento de NDA proporcionado cumple",
			textNote2: " con sus estándares para un documento NDA.",
			agreeButton: "Estoy de acuerdo con los Términos y Condiciones",
			checkboxText: "Al seleccionar la casilla, usted acepta los",
			checkboxText2: "Términos y Condiciones",
			checkboxText3:
				"cada vez que envíe un NDA, y esta pantalla ya no se mostrará.",
			ndaRecipient: "Ingrese su destinatario de NDA",
			ndaRecipient2: "Nombre del destinatario",
			ndaEmail: "Correo Electrónico",
			or: "O",
			sendViaPhone: "Enviar a teléfono",
			sendViaEmail: "Enviar a correo",
			ndaPhone: "Número de teléfono",
			send: "Enviar",
			contactList: "Contactos",
			myContacts: "Mis contactos",
			yourEntity: "Su entidad!",
			yourEntity2: "Su entidad",
			recipient: "Destinatario",
			all: "Todo",
			incoming: "Entrantes",
			outgoing: "Salientes",
			executed: "Ejecutados",
			sent: "Enviado",
			received: "Recibido",
			read: "Leído",
			signToComplete: "Firma para completar",
			resendNDA: "Reenviar NDA",
			alerts: "Alertas",
			new: "Nuevo",
			readAlert: "Leído",
			noNew: "No hay nuevas notificaciones",
			noRead: "No hay notificaciones leídas",
			settings: "Configuraciones",
			accountSettings: "Configuracion de la cuenta",
			preferences: "Preferencias",
			socialmedia: "Social Media",
			desactive: "Desactivar cuenta",
			editPassword: "Editar contraseña",
			editAvatar: "Editar avatar",
			setupEntity: "Configura tu Entidad",
			notifications: "Notificaciones",
			selectLenguaje: "Seleccionar lenguaje",
			english: "Ingles",
			spanish: "Español",
			facebookAccount: "Cuenta de Facebook",
			googleAccount: "Cuenta de Google",
			linkedinAccount: "Cuenta de Linkedin",
			appleAccount: "Cuenta de Apple",
			deleteAccount: "Eliminar cuenta",
			settingsModal1:
				" Para recibir notificaciones, por favor asegúrese de que las notificaciones",
			settingsModal2:
				"estén habilitadas en la configuración de su navegador.",
			settingsModal3: "Para deshabilitar las notificaciones, desactive ",
			settingsModal4: "en la configuración de su navegador.",
			firstAndLast: "Nombre y Apellido del Perfil",
			editYourEntity: "Editar tu entidad",
			documents: "Documentos",
			yourSignature: "Tu Firma",
			documentReady: "Tu Documento está Listo.",
			documentReady2: "Haz clic abajo para Revisar y Firmar!",
			submitAndDownload: "Enviar y Descargar",
			downloadMessage1:
				"Este número ya está asociado con una cuenta registrada.",
			downloadMessage2: "Haz clic abajo para Revisar e Iniciar sesión!",
			openNDA: "Abre la aplicación para ver el NDA.",
			downloadMessage3: "El Documento no fue encontrado,",
			downloadMessage4: "Haz clic abajo para Revisar y Firmar!",
			downloadMessage5: "Tu Documento ya fue leído,",
			downloadMessage6: "Haz clic abajo para Revisar y Firmar!",
			requestCustom: "Solicitar NDA Personalizado",
			helpWithContracts: "Ayuda con contratos",
			canvasText1:
				"Aquí encontrarás las últimas actualizaciones y anuncios sobre Confidential Bee. ¡Mantente informado y no te pierdas ninguna nueva funcionalidad!",
			canvasText2:
				"Conéctate con nosotros en las redes sociales. Síguenos para obtener noticias, actualizaciones y más. ¡Búscanos en tus plataformas favoritas!",
			canvasText3:
				"Consulta nuestra sección de preguntas frecuentes (FAQ) para obtener respuestas a preguntas comunes y consejos útiles sobre cómo utilizar Confidential Bee de manera efectiva.",
			canvasText4: "¡Aprovecha al máximo tu experiencia con nosotros!",
			canvasText5:
				"Sumérgete en un mundo de conocimiento con Confidential Bee.",
			removeTitle: "¡Lamentamos verte partir!",
			removeText1: "Si deseas eliminar tu cuenta de Confidential Bee,",
			removeText2: "sigue los pasos a continuación:",
			logIn: "Inicia sesión:",
			logInText:
				"Inicia sesión en tu cuenta de Confidential Bee utilizando tu nombre de usuario y contraseña.",
			accessAccountSettings: "Configuración de la cuenta:",
			accessAccountSettingsText:
				"Una vez iniciado sesión, navega hasta la configuración de tu cuenta. Esto generalmente se encuentra haciendo clic en el icono de perfil o nombre.",
			findAccountRemovalOption: "Opción de eliminación de cuenta:",
			findAccountRemovalOptionText:
				'Busca la opción para eliminar o eliminar tu cuenta. Esto puede ser etiquetado como "Eliminar cuenta", "Borrar cuenta", o algo similar.',
			followPrompts: "Sigue las instrucciones:",
			followPromptsText:
				"Sigue las instrucciones o los consejos proporcionados para iniciar el proceso de eliminación de la cuenta. Es posible que se le solicite confirmar su decisión o proporcionar una razón para eliminar su cuenta.",
			confirmation: "Confirmación:",
			confirmationText:
				"Una vez que haya completado los pasos para eliminar su cuenta, debería recibir un mensaje de confirmación que indica que su cuenta se ha eliminado correctamente.",
			pleaseNote:
				"Tenga en cuenta que la eliminación de su cuenta es irreversible y resultará en la eliminación permanente de todos los datos de la cuenta, incluyendo cualquier documento guardado o información asociada con su cuenta.",
			ifYouHaveQuestions:
				"Si tiene alguna pregunta o encuentra algún problema al intentar eliminar su cuenta, comuníquese con nuestro equipo de soporte en",
			ifYouHaveQuestionsText: "info@softsmart.com",
			forAssistance: "para obtener ayuda.",
			thankYou: "Gracias por utilizar ConfidentialBee.",
			editYourEntityTitle: "Edita tu entidad!",
			nameEntry: "Nombre de la entidad",
			oficialName: "Ingrese su nombre oficial de la entidad:",
			forgotPassword: "Olvidaste tu contraseña?",
			SendVerification:
				"Te enviaremos un código de verificación a tu teléfono.",
			forgotMessage: "Acabamos de enviarte un mensaje de texto,",
			forgotMessage2: "por favor, comprueba tu teléfono.",
			passwordMessage1: "La contraseña debe contener al menos",
			passwordMessage2: "6 caracteres incluyendo",
			passwordMessage3: "1 símbolo y 1 número",
			newPassword: "Ingresa tu nueva contraseña",
			newPassword2: "Nueva contraseña",
      deleteAccounttitle: "¿Eliminar cuenta?",
      deleteAccountText: "Lamentamos verte partir. ¿Estás seguro de que deseas cancelar tu cuenta?",
      deleteAccountText2: "Ten en cuenta que esta acción resultará en la pérdida permanente de todos tus datos.",
      no: "No",
      yes: "Si",
	  accept1:"Acepto los",
	  accept2:"términos y condiciones de la NDA",
	  communications:"Comunicaciones",
	  profile:"Perfil"
		},
		{
			left: "Izquierda",
			justify: "Justificar",
			right: "Derecha",
			termsnda: "Términos de NDA",
			text1: 'Para llevar a cabo el negocio mutuo entre las partes y/o sus respectivos afiliados (la "Transacción"), tanto la Parte Divulgadora como la Parte Receptora reconocen que existe la necesidad de divulgarse mutuamente cierta información respecto a sí mismos y/o a sus afiliados. Toda dicha información, entregada por o en nombre de la Parte Divulgadora y/o sus Representantes (como se define más adelante) a la "Parte Receptora" y/o sus Representantes, ya sea proporcionada antes o después de la fecha de este Acuerdo y sin importar la forma en que se proporcione, junto con todos los análisis, compilaciones, estudios u otros documentos o registros preparados por la Parte Receptora y/o sus Representantes, en la medida en que dichos análisis, compilaciones, estudios, documentos o registros contengan, reflejen o sean generados a partir de dicha información, se denomina en el presente como "Información Confidencial". En consideración de la oportunidad de considerar dicha Información Confidencial, ambas partes acuerdan lo siguiente:',
			text2: 'La Información Confidencial será utilizada por la Parte Receptora únicamente con el propósito de evaluar la Transacción. La Información Confidencial será mantenida estrictamente confidencial por la Parte Receptora, excepto que la Información Confidencial o cualquier parte de la misma pueda ser divulgada a afiliados, directores, oficiales, empleados, asesores, abogados, agentes, personas controladoras, fuentes de financiamiento u otros Representantes (cada uno, un "Representante", y colectivamente, los "Representantes") de la Parte Receptora que necesiten conocer dicha Información Confidencial con el propósito de evaluar la Transacción y que acuerden tratar dicha Información Confidencial de acuerdo con los términos de este Acuerdo.',
			text3: 'El término "Información Confidencial" no incluye información que (i) es o se convierte en generalmente disponible al público que no sea como resultado de una violación de los términos de este Acuerdo por parte de la Parte Receptora y/o cualquiera de sus Representantes, (ii) es o ha sido adquirida o desarrollada independientemente por la Parte Receptora y/o cualquiera de sus Representantes sin violar ninguno de los términos de este Acuerdo, (iii) estaba en posesión de la Parte Receptora y/o cualquiera de sus Representantes antes de ser proporcionada a la Parte Receptora y/o cualquiera de sus Representantes por o en nombre de la Parte Divulgadora de acuerdo con los términos de este documento o (iv) es recibida de una fuente distinta a la Parte Divulgadora y/o cualquiera de sus Representantes; siempre que, en el caso de (iii) y (iv) anteriores, la fuente de dicha información no era conocida por la Parte Receptora como sujeta a una obligación de confidencialidad hacia la Parte Divulgadora o cualquier otra parte con respecto a dicha información.',
			text4: "En el caso de que la Parte Receptora o cualquiera de sus Representantes reciba una demanda o solicitud para divulgar toda o cualquier parte de la Información Confidencial de la Parte Divulgadora bajo los términos de una citación u orden emitida por un tribunal de jurisdicción competente o bajo una demanda de investigación civil o un proceso similar, (i) si es legalmente permisible, la Parte Receptora acuerda notificar rápidamente a la Parte Divulgadora sobre la existencia, términos y circunstancias que rodean dicha demanda o solicitud y (ii) si la Parte Receptora o su Representante aplicable, en opinión de su abogado, está obligado a divulgar toda o parte de la Información Confidencial de la Parte Divulgadora, la Parte Receptora o su Representante aplicable puede divulgar esa Información Confidencial que su abogado aconseje que está obligado a divulgar y hará esfuerzos razonables para obtener aseguramiento de que se otorgará un tratamiento confidencial a la Información Confidencial que se esté divulgando.",
			text5: "A menos que sea requerido por la ley, o a menos que se disponga lo contrario en un acuerdo definitivo final con respecto a la Transacción cuando, como y si se ejecuta, ambas partes y sus respectivos Representantes no divulgarán, sin el consentimiento previo por escrito de la otra parte, a ninguna persona (distinto a los Representantes de las partes aquí presentes que necesiten conocer dicha información con el propósito de evaluar la Transacción y que acuerden tratar dicha información de acuerdo con los términos de este Acuerdo): (i) que se están llevando a cabo discusiones o negociaciones en relación con la Transacción, o (ii) cualquiera de los términos o condiciones de la Transacción.Este Acuerdo ni la divulgación ni la recepción de Información Confidencial se interpretará como la creación de cualquier obligación de una parte de proporcionar Información Confidencial",
			text6: "Nada en este Acuerdo despojará a la Parte Divulgadora de cualquiera de sus derechos, títulos o intereses en o con respecto a cualquiera de su Información Confidencial. Dentro de diez (10) días después de recibir una solicitud de la Parte Divulgadora para la destrucción de la Información Confidencial, la Parte Receptora y sus Representantes destruirán toda la Información Confidencial proporcionada a la Parte Receptora y/o cualquiera de sus Representantes por o en nombre de la Parte Divulgadora.",
			text7: "No obstante lo anterior, la Parte Receptora puede retener la Información Confidencial, siempre que dicha Información Confidencial sea retenida sujeta a las disposiciones de confidencialidad contenidas en el presente por el tiempo que sea retenida por la Parte Receptora, independientemente del término de este Acuerdo, donde y en la medida en que la Información Confidencial sea: (a) retenida en sistemas de respaldo de acuerdo con sus procedimientos automatizados de seguridad y/o recuperación ante desastres vigentes de tiempo en tiempo; (b) incorporada de manera limitada en análisis o documentos preparados por la Parte Receptora; o (c) retenida por abogados para el cumplimiento normativo, o para uso en la persecución, defensa o resolución de una disputa bajo este Acuerdo.",
			text8: "La Parte Receptora reconoce y acuerda que ni la Parte Divulgadora ni ninguno de sus Representantes está haciendo ninguna representación o garantía en cuanto a la exactitud o integridad de cualquiera de la información proporcionada en virtud de este Acuerdo a la Parte Receptora o cualquiera de sus Representantes y cada una de las Partes Receptora y Divulgadora además reconoce y acuerda que ninguna parte tiene ninguna obligación hacia la otra parte o cualquiera de sus Representantes de autorizar o llevar a cabo con la otra parte la Transacción.",
			text9: "Ambas partes acuerdan que los daños monetarios pueden no ser un remedio suficiente para cualquier incumplimiento de los términos de este Acuerdo por parte de la Parte Receptora o cualquiera de sus Representantes, y que, además de todos los otros remedios en derecho o en equidad a los que la Parte Divulgadora pueda tener derecho, la Parte Divulgadora puede tener derecho a buscar el cumplimiento específico y medidas cautelares u otro alivio equitativo como un remedio para cualquier incumplimiento de este tipo.",
			text10: "Si se encuentra en un juicio final por un tribunal de jurisdicción competente (no sujeto a más apelaciones) que cualquier término o disposición del presente es inválido o inejecutable, será ineficaz solo en la medida de la invalidez, de modo que el resto de la disposición y el Acuerdo continuarán en pleno vigor y efecto.",
			text11: "Este Acuerdo no se interpretará para limitar el derecho de la Parte Divulgadora, la Parte Receptora, o cualquiera de sus respectivos Representantes de desarrollar o adquirir independientemente productos, servicios o tecnología sin el uso de la Información Confidencial de la otra parte.",
			text12: "La validez e interpretación de este Acuerdo se regirán por, y se interpretarán y harán cumplir de acuerdo con, las leyes del Estado de Carolina del Norte aplicables a acuerdos hechos y totalmente ejecutados allí (sin tener en cuenta sus reglas con respecto a conflictos de leyes).",
			text13: "Ninguna de las partes de este Acuerdo cederá en su totalidad o en parte sus derechos u obligaciones bajo este Acuerdo sin el consentimiento expreso por escrito de la otra parte. Este Acuerdo será vinculante y redundará en beneficio de los sucesores y cesionarios permitidos de cada parte.",
			text14: "Este Acuerdo representa la comprensión total de las partes con respecto al tema y, con respecto al tema, sustituye a todos los acuerdos escritos u orales hechos previamente por o en nombre de las partes. Este Acuerdo no puede ser modificado excepto mediante un acuerdo escrito firmado por ambas partes.",
			text15: "Para la conveniencia de las partes, cualquier número de copias de este Acuerdo puede ser ejecutado por las partes aquí presentes. Cada una de esas copias será, y se considerará, un instrumento original, y todas esas copias juntas constituirán uno y el mismo acuerdo.",
			text16: "El término de este Acuerdo será de tres (3) años a partir de la fecha del presente.",
			text17: "EN FE DE LO CUAL, este Acuerdo ha sido debidamente ejecutado en la fecha arriba mencionada.",
		},
		{
			warningEmail: "El email del dispositivo no fue encontrado",
			warningDevice: "El id del dispositivo no fue encontrado",
			warningRequireUsername:
				"El usuario y la contraseña son campos requeridos",
			warningConfirmPassword:
				"El campo de confirmación de la contraseña es requerido",
			warningConfirmPassword2:
				"La confirmación de la contraseña no coincide",
			successRegister: "Se ha registrado el usuario exitosamente",
			successAvatar: "Tu avatar se ha actualizado con éxito!",
			warningFistname: "El campo de nombre es requerido",
			warningLastname: "El campo de apellido es requerido",
			warningPhone: "El campo de número de teléfono es requerido",
			warningEmail2: "El campo de correo electrónico es requerido",
			warningPassword: "El campo de contraseña es requerido",
			successStepper: "La entidad fue registrada",
			warningFile: "Seleccione un archivo antes de continuar",
			successUpdate: "Actualización Exitosa!",
			warningUser: "Usuario no encontrado",
			successVerify: "El correo electrónico ya ha sido verificado!",
			successVerify2: "Su teléfono ya ha sido verificado!",
			successCustom: "Su solicitud de NDA personalizado ha sido solicitado.",
			warningSetUp: "Por favor configure primero su entidad ",
			warningRequestEmail: "Por favor ingrese su correo electrónico para continuar",
			warningEmailAndPhone: "El correo electrónico y número de teléfono son requeridos",
			successRegistered: "Registro exitoso!",
			successProfile: "Su perfil ha sido actualizado",
			successAddress: "Su dirección ha sido actualizada",
			warningNoData: "No hay datos disponibles",
			successEntity: "Su entidad ha sido actualizada",
			successSignature: "Su firma ha sido actualizada",
			warningTwoFields: "El campo de dos campos debe ingresarse",
			successUpdatedPassword: "Contraseña actualizada",
			warningEntityAndSignature:
				"Debe configurar su entidad y firma, por favor configure primero!",
			successDocument: "Su documento ya fue enviado!",
			warningUsername: "El nombre de usuario es requerido",
			warningPhoneOrEmail: "El número de teléfono o correo electrónico es requerido",
			successNDA: "El NDA fue enviado exitosamente!",
			sendAgain:"Reenviar",
			sendAgainText:"¿Desea enviar este documento nuevamente?",
			ndaCopy:"Copia del Documento",
			ndaCopyText:"Este documento ya está firmado, ¿desea recibir una copia en su correo electrónico?",
			signed:"¡Felicitaciones!",
			signedText:"El documento ha sido firmado exitosamente!",
			signedText2:"Recibirá una copia del documento por correo electrónico.",
		},
		{
			termsTitle: "Términos de Servicio",
			efecitveDate: "Fecha de Vigencia: 04/08/24",
			termsText1:
				'¡Bienvenido a Confidential Bee! Estos Términos de Servicio ("Términos") rigen su uso del sitio web ',
			termsEmail: "www.confidentialbee.com",
			termsText2:
				'("Sitio") y de la aplicación móvil  ("App") proporcionada por Confidential Bee. Al acceder o utilizar el Sitio o la App, usted acepta estar sujeto a estos Términos. Si no está de acuerdo con alguna parte de los Términos, no puede acceder al Sitio o a la App.',
			termsUseOfSite: "Uso del Sitio y la App:",
			termsUseOfSiteText:
				"Al utilizar el Sitio o la App, usted declara que tiene al menos la mayoría de edad en su estado o provincia de residencia, o que es mayor de edad en su estado o provincia de residencia y nos ha dado su consentimiento para permitir que cualquiera de sus dependientes menores de edad utilice este Sitio o App.",
			termsUserAccount: "Cuenta de Usuario:",
			termsUserAccountText:
				"Para acceder a ciertas funciones del Sitio o la App, es posible que se le solicite crear una cuenta. Usted es responsable de mantener la confidencialidad de su cuenta y contraseña, y de restringir el acceso a su computadora o dispositivo móvil. Usted acepta asumir la responsabilidad por todas las actividades que ocurran bajo su cuenta o contraseña.",
			termsProhibitedActivities: "Propiedad Intelectual:",
			termsProhibitedActivitiesText:
				"El Sitio y la App, así como su contenido original, características y funcionalidades, son propiedad de Confidential Bee y están protegidos por leyes internacionales de derechos de autor, marcas comerciales, patentes, secretos comerciales y otras leyes de propiedad intelectual o derechos de propiedad.",
			termsProhibited: "Actividades Prohibidas: ",
			termsProhibitedText:
				"Usted acepta no utilizar el Sitio o la App para ningún propósito ilegal ni para violar ninguna ley en su jurisdicción. También acepta no:",
			termsProhibitedText2:
				"Publicar o transmitir cualquier contenido que sea difamatorio, obsceno, pornográfico, abusivo, ofensivo o que, de alguna otra manera, viole cualquier ley o derecho de terceros.",
			termsProhibitedText3:
				"Interferir con o interrumpir el Sitio o la App, o los servidores o redes conectados al Sitio o la App.",
			termsProhibitedText4:
				"Intentar obtener acceso no autorizado a cualquier parte del Sitio o la App o a cualquier otro sistema o red conectada al Sitio o la App.",
			termsLimitation: "Limitación de Responsabilidad:  ",
			termsLimitationText:
				"En ningún caso Confidential Bee, ni sus directores, empleados, socios, agentes, proveedores o afiliados, serán responsables de ningún daño indirecto, incidental, especial, consecuente o punitivo, incluyendo, sin limitación, pérdida de ganancias, datos, uso, buena voluntad u otras pérdidas intangibles, que resulten de (I) su acceso o uso o incapacidad para acceder o usar el Sitio o la App; (II) cualquier conducta o contenido de cualquier tercero en el Sitio o la App; (III) cualquier contenido obtenido del Sitio o la App; y (IV) acceso no autorizado, uso o alteración de sus transmisiones o contenido, ya sea basado en garantía, contrato, agravio (incluida la negligencia) o cualquier otra teoría legal, ya sea que se nos haya informado o no de la posibilidad de dicho daño, e incluso si se encuentra que un remedio establecido en este documento no ha cumplido con su propósito esencial.",
			termsIndemnification: "Indemnización:",
			termsIndemnificationText:
				"Usted acepta indemnizar y mantener indemne a Confidential Bee y sus afiliados, oficiales, directores, agentes y empleados de cualquier reclamación o demanda, incluidos los honorarios razonables de abogados, realizados por cualquier tercero debido a o que surjan de su incumplimiento de estos Términos o de los documentos que incorporan por referencia, o su violación de cualquier ley o de los derechos de un tercero.",
			termsGoverning: "Ley Aplicable:",
			termsGoverningText:
				"Estos Términos se regirán e interpretarán de acuerdo con las leyes de [inserte la jurisdicción], sin tener en cuenta sus disposiciones sobre conflicto de leyes.",
			termsChanges: "Cambios en los Términos:",
			termsChangesText:
				"Confidential Bee se reserva el derecho, a nuestra sola discreción, de modificar o reemplazar estos Términos en cualquier momento. Si una revisión es material, intentaremos proporcionar al menos 30 días de aviso antes de que cualquier nuevo término entre en vigencia. Lo que constituye un cambio material será determinado a nuestra sola discreción.",
			termsClosing: "Declaración Final:",
			termsClosingText:
				"Gracias por revisar nuestros Términos de Servicio. Al acceder o utilizar el sitio web o la aplicación móvil de Confidential Bee, usted acepta cumplir con estos Términos. Si tiene alguna pregunta o inquietud sobre estos Términos, por favor contáctenos en info@softsmart.com. Su uso continuo del Sitio o la App indica su aceptación de estos Términos.",
		},
		{
			privacyTitle: "Política de Privacidad",
			privacyEffectiveDate: "Fecha de Vigencia: 04/08/24",
			privacyText1:
				'Bienvenido a Confidential Bee! Esta Política de Privacidad describe cómo recopilamos, utilizamos, mantenemos y divulgamos la información obtenida de los usuarios ("Usuarios") de nuestro sitio web ',
			privacyText2:
				'y de nuestra aplicación móvil ("App"). Esta política se aplica a todos los productos y servicios ofrecidos por Confidential Bee.',
			privacyInformationCollection: "Recopilación de Información:",
			privacyInformationCollectionText:
				"Información Personal: Podemos recopilar información de identificación personal de los Usuarios de diversas maneras, incluyendo cuando visitan nuestro sitio, se registran en el sitio, se suscriben a nuestro boletín, responden a una encuesta, completan un formulario, y en relación con otras actividades, servicios, características o recursos que ponemos a disposición en nuestro Sitio o App. Los Usuarios pueden ser solicitados, según corresponda, para proporcionar su nombre, dirección de correo electrónico, dirección postal, número de teléfono y otros detalles relevantes.",
			privacyHowWeUseCollectedInformation:
				"Cómo Usamos la Información Recopilada:",
			privacyHowWeUseCollectedInformationText:
				"Podemos utilizar la información que los Usuarios proporcionan para diversos fines, incluyendo:",
			privacyHowWeUseCollectedInformationText2:
				"Personalizar la experiencia del usuario",
			privacyHowWeUseCollectedInformationText3:
				"Mejorar nuestro Sitio y App",
			privacyHowWeUseCollectedInformationText4:
				"Enviar correos electrónicos periódicos o notificaciones.",
			privacyHowWeUseCollectedInformationText5: "Procesar transacciones",
			privacyHowWeUseCollectedInformationText6:
				"Responder a consultas, preguntas y/o otras solicitudes",
			privacyInformationProtection: "Protección de la Información :",
			privacyInformationProtectionText:
				"Adoptamos prácticas adecuadas de recopilación, almacenamiento y procesamiento de datos y medidas de seguridad para proteger contra el acceso no autorizado, alteración, divulgación o destrucción de la información personal de los Usuarios, nombre de usuario, contraseña, información de transacciones y datos almacenados en nuestro Sitio o App.",
			privacySharingYourPersonalInformation:
				"Compartir su Información Personal:",
			privacySharingYourPersonalInformationText:
				"No vendemos, intercambiamos ni alquilamos la información de identificación personal de los Usuarios a terceros. Podemos compartir información demográfica genérica agregada no vinculada a ninguna información de identificación personal sobre visitantes y usuarios con nuestros socios comerciales, afiliados de confianza y anunciantes.",
			privacyThirdPartyWebsites: "Sitios web de terceros:",
			privacyThirdPartyWebsitesText:
				"Los Usuarios pueden encontrar publicidad u otro contenido en nuestro Sitio y App que enlaza a sitios y servicios de nuestros socios, proveedores, anunciantes. No controlamos el contenido ni los enlaces que aparecen en estos sitios y no somos responsables de las prácticas empleadas por sitios web vinculados a o desde nuestro Sitio o App.",
			privacyChangesToThisPrivacyPolicy:
				"Cambios en Esta Política de Privacidad :",
			privacyChangesToThisPrivacyPolicyText:
				"Confidential Bee se reserva el derecho de actualizar esta política de privacidad en cualquier momento. Cuando lo hagamos, revisaremos la fecha actualizada al final de esta página. Animamos a los Usuarios a revisar con frecuencia esta página para cualquier cambio y mantenerse informados sobre cómo ayudamos a proteger la información personal que recopilamos. Usted reconoce y acepta que es su responsabilidad revisar periódicamente esta política de privacidad y estar al tanto de las modificaciones.",
			privacyYourAcceptanceOfTheseTerms:
				"Su Aceptación de Estos Términos",
			privacyYourAcceptanceOfTheseTermsText:
				"Al utilizar este Sitio o App, usted manifiesta su aceptación de esta política.",
			termsClosing: "Declaración Final:",
			termsClosingText:
				"Estamos comprometidos a mantener la privacidad y seguridad de su información personal. Al utilizar Confidential Bee, usted acepta los términos descritos en esta Política de Privacidad. Si tiene alguna pregunta o inquietud acerca de nuestras prácticas o esta política, por favor contáctenos en info@softsmart.com. Gracias por confiar en nosotros con su información.",
		},
	],
};

export const data = [
	{
		id: 1,
		first_name: "Robers",
		last_name: "Freezor",
		photo: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAJ3SURBVBgZBcHPi5RlHADwz/POOzu7o21bIWVsiiAR1kYdJOwHdAgKO3QQDMqCTv4XdQikg1BCUnTQCIK6REVdOnWTClIRjAwNCdFy1t1xdnZm3nee59vnkyICAOSTJyu5HCsdR6PESju4fXG6vvFBOxzOVd7r7tn7Zju4vbF58dJnk7Y9VQMAaNu3PbTr03rtyR6hGqw/N/3u+6ct9mYrrx5+cXHfXpHLzqVffn3/2pnPl2oAgNJfPN5de7xnNGK8re733Xf0yCFtq2oKl/+UcO/aE1K3+1YNAJBTtdptC+sbRGHzrqruUDLDIaUw3mbnDlFKrwYAyFujmbZhe8w8kzOzGfM5udDMmGyjaPM86is/HrywuHzgQEoVWKh0ujf2WFjewfUb5DklyC2TGbMpqw+7c/6CzeHdH+oU1WOPPH+2m1IiyPtvWf/oQ/es7ra0Z5Wr19gYkVt6XQ486s6li37/+tvfxpPJido8pkrTm936RG46VMv6x9YMf7rhvzNn7T78soUqsT3R7Fzyx6mPjfdv5eF4/NqRweBmrU0VlHkfYevaOcPL5+nhFa6XL+mhB/TfqfWtdJ796spNqJWqo+oY/X1Fc+cfZbbtgYOvW3nqDUlS5q1mMKEU3fsX1Mv7/HX6GQC1OUnHrhfeJTJRkDWDb8hjZEkjTDSDic6OEwCg1kZEZM2/X6AQgUwUES3RijIhjykTAAC1JgqBIAqRRTTEnGhEmZK3lTwSeQsFANRmUaREFBFzoqXMRJkRU1GmooxF3qJMUAGAOtrSy+NN0oNSSuiITiXpgKRCIhJqeTICAHXMZj9fPf3SIYEEAAAACCSUcg7gf+9HV+4TlzZTAAAAAElFTkSuQmCC",
	},
	{
		id: 2,
		first_name: "Cassie",
		last_name: "Arson",
		photo: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAHvSURBVBgZpcG/S9RxHMfx5+fuq10d/SJKQ+rUaLoWw60bIoIgaKhBpDksDBFbCooQiqAlURCp/gIjHIQWa4h+QDRYtFhDqOEdSqCSelbfe79f+UUEg35Jj0eQxP8Ip7sGLxaONt+eLSsrAe7IHXNDLlyOzHAJmWHubKuOl8bGipeH7rT0R81HmvuqMtlUTYaNyC6VrQ/oj8qxUuXZRTZqOVaKFZFMXDtTx0Z19E6SiCRHEiIhEBACb0ZH+Z3DTU2YG4kICTOjrXeCNfc760kUCgV+ZXFxEa8YicjccIm7HTkIAgVc4k/cHTcnEcmFmxMCyFkhCCKfzzM/P4fEOgEQAXA3EpG74W6IVYEVgrH3Y/xkdBh7PURl+hPp3bUc2nwMOEVUiWPMjM57M6zpbashUSgUSEwNDzBXfMXBs+1sasiz/G6E7PMnPD5e1RHJHHen59weQCTcnfXGH/bQ3HqBzMen8OwmW7bvoCGXY/SDuiJ3x12AEwCxqrHxAKVSCQh8L02QqW2Ak5dYE3XvJe2hPiqOTz64MRi3uBlujpnh7lgc4+64REtmJ+W3j8gOt/NteZoysPAljaUpBUn8zYvWuu7qrdmr+3ZVoig1xcLnChMzaYu/6nqQxL942br/ytJs6XzaQs7SKgoGToxUbv0ALswWDRrf9Y0AAAAASUVORK5CYII=",
	},
	{
		id: 3,
		first_name: "Patti",
		last_name: "MacCaughey",
		photo: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAQAAAC1+jfqAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAADoSURBVBgZBcExblNBGAbA2ceegTRBuIKOgiihSZNTcC5LUHAihNJR0kGKCDcYJY6D3/77MdOinTvzAgCw8ysThIvn/VojIyMjIyPP+bS1sUQIV2s95pBDDvmbP/mdkft83tpYguZq5Jh/OeaYh+yzy8hTHvNlaxNNczm+la9OTlar1UdA/+C2A4trRCnD3jS8BB1obq2Gk6GU6QbQAS4BUaYSQAf4bhhKKTFdAzrAOwAxEUAH+KEM01SY3gM6wBsEAQB0gJ+maZoC3gI6iPYaAIBJsiRmHU0AALOeFC3aK2cWAACUXe7+AwO0lc9eTHYTAAAAAElFTkSuQmCC",
	},
	{
		id: 4,
		first_name: "Marguerite",
		last_name: "Jakobssen",
		photo: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAKuSURBVDjLlZLLS5RRGMbf7zrzebdw8FaSqJAbF0GgRBvptinoPxDKRdDKQjBw2TKClgVJCwmUyMBKydDKS9TCME2tRhs1cZwZ57ud853bfB0HiiAVOvBuDuf5ve/7nEcJwxAOOgPTtk4Fr6ZU1OCAVyBCm2Td9jEdcxG5pBwEeDyZtaRwjAvWSpkAxjkITsGKqJBIuvB903upH9QdE3rd1MLW0gIVCMsBoYq8U8H2CUQNBdJZp33fCe6PbJo+4XZVmRHFhEDKCXaB4Accii0NFlfT8GNt56a6X/er56qog/Cd1aQjRRQictasR8B2EXgIQyblAbX95X9WeDSRPiriz3oZY1pvZ2dH590Z7GB+q7LcjBZHVdhMBaCpEfBsCXDR9p8V+t9lLGlUP7PXLxyJbkUMw4DZ2dm+rq6ujjPdz09xTEZrY8VWYZEh/WAwNxWHwEV1eYDsqsuOCxFDaYRQwGZ8ljeUE31+fr4PY3xFVulM5mQzC4LRypoSy037kEykvuZytDnvAQ5oNSa8scAE0JQcGIeb9LcrJl02Tj+U4gcIoanG8MU35qKzK58SaCux9ZSLoGVxvJvnPfAQrQEQEhTCRhpBYVQB61CNyZY+v6qvrzdisRgMDg6O1+kjbUt+23EpTPz2LA9wMa7QFJBuhxIWQHKHQWmBDrXHGozJuTfQ4sWBEDI9NDSUkc8zf5ueB9gubiqyVJBacBDZXQm2MhSiugZW7QkYfj/NuGi5ttd3a9uxi6bM9FhFmak5fgCmHEXqQFcVEDkBiZVt+edhz8fh7om9AHrWxV5JgWoImXMfE1jbsMHd8QF7AQQyONjxp4UQ9/YLnJ710JgaGucXUi6sr2cY84MeQfmyCOg2p3RD5PjPL69v8H0ByEWXnSR7IoPSzjEt+jDQQeE/zi9kq6pv7shelwAAAABJRU5ErkJggg==",
	},
	{
		id: 5,
		first_name: "Tamas",
		last_name: "Charity",
		photo: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAIsSURBVDjLnZPNi1JhFMbvKtoHBa1atgmCtv0VrVq0aCkGCn6mYH47ip8IflAKhibpRke00BnnKiKoiKA7qSkF08FvvToak/f0ngu2qBYzXngu3Jf3+b3nPee5VCAQcPj9/ucAQB0iyufzPXS73Wd2u/3RQQB8Wa1Wiclkqms0mrsHAQwGwy21Wn2qUCjOxGLxHVyrVCpHpVKJpWmazeVy20wmQyeTyaf/BaAKhcIrkUh04XA4vhSLxTIxX5IHULMCDd+PkxCLxbaRSETxD6DVamUbjcavWq22LZfLMBqNgGEYuJgs4TxbhG9PHnManuQgGAyypOnv/wCazaat2+1yJ735pOCMy+USBuMFvPzIwosPAMW3xzDwemA+HHL78vk82Gy2Iw5APtZoms/nHGCv2WwGP4Zz6AwWsFgsYLVacUI47jUajTvS9GcUaQ6LgL/Ne3U6HSBVgtPpZFHT6ZSrst1ug1Kp/EolEokdUveGPWAymUA2m4V0Og1kD5AxX1osFo1er2fxGpvNBiQSCVDxeJzp9/tcWWjEcsfjMVSrVUilUth5IEYgo/6Md1apVDSu46FCoRCoaDR6gp1HIwLQ7PV6ezKZbMnj8YBoKZVKUzqd7h4C5HL5bZKVU4FAMOHz+U4qHA6/RiJOAgFIJvFmrp3EUCj0gMyVqdfr0Ov1YL1eg8vl2t0oyh6P5x2JKZAwAQkVNuznjQDkb7xPgnFuNpuvyHyvtFpt+bqA3zDZAQQexaeGAAAAAElFTkSuQmCC",
	},
	{
		id: 6,
		first_name: "Brynna",
		last_name: "Ockleshaw",
		photo: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAJFSURBVDjLjZPPS1RRFMc/9703ao40jqbkj9EkjKBFQdaioiCKskVLqXWBJFTQIqJFhYsIgoRctG3TPxCIoFCiCZERUisXplLa+IPUkcZ57/44LUanMYX6ci/ncjn3c865514lIkxMTIhzDucc1lqstRhjCrZ4aq0LtqOjQwUA1lrq6xtZyWRABPIDRBBARAprREgmK+nv7wOgAFhZXeXW81H+R087jxFF0R+AMWYjJDSmmqgoLyFVE6esNCCnBW0c2oIVeP9hHHFCGIYAeABa63yagFKKbM6QXsmRDS0iYKwQGUdo8j4ibM1Aa43bzE8plFKsZQ1OQirjMYLAx3OCbACcuO0AcXmEKoKsR5ZIO+LlJfi+h6fyMcTtAKCoBKVUHgKIUmRDi/Ikvw+4v0uIogjP9wCYmZ76Zxf8wN8OKIkFPLl+BGuFWCzGwvw88YrdiAjLy0skvw6THXmF/jFD+mEtDWX7twCeDQ29PVP0yqq01m8aGhquGWPwhl+O1ZRn2o5fvaFKWw6x/nmAxMigGzwXu6k221esnp6e28aYyydPnT6bSCSZvHtCznfdV7smh2D2HSQqWQqa+TQwMhUUH+zt7a3RWi+0th6gqnoPRkM6vUg8l1Fle1vg0p2Cb/CoDt+pfVsAYRi2pVIp2tsvYoxBRBARxmrryI73EX/dRbieJgusZXysz9y2Erq7uz9qrY8W/8CDq1847H+juVoTeN9ZWzRMz/tW5+TBjnewk0avNN379XOu07eq2foyK/DiwoB5/BuCJmv1SL6PpQAAAABJRU5ErkJggg==",
	},
	{
		id: 7,
		first_name: "Serge",
		last_name: "Dimelow",
		photo: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAQAAAC1+jfqAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAACjSURBVCjPY/jPgB8yEKmgPKH8ffn/0n4IL3F99P+QAjQTyveX/IexIwWCz2NYUbw/7z/CYK/9GApy92cgKXDEVJC+PxFJgQWmgoT9kUgK9DEVROwPRFKghqnAv9/7v2MAhK3iINePocBNwf69xXlDhf8Myg4y58UUsISkmYL+fI39ivul+0UMSA/q/wza/1X+y/0X/y/0n+c/+3/m/6SbgAsCAM8i/W7eee6fAAAAAElFTkSuQmCC",
	},
	{
		id: 8,
		first_name: "Kikelia",
		last_name: "Moncarr",
		photo: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAH2SURBVBgZpcE/SJRxHMfx9+/uuT8qlgR6mvTHqyGwKMWpbmhvskFa2spFRJxaGlprKBQcKmhqcWloaQ2ixcFaDhUKLDDJSFHvT+fzfD/ffJC2Go5er+Du/I8wPrs4Vbk+9nC74V3ugIRLmAyXIxduhtxxM0ziWD6ur6xs3Hv1eGIhGrs2Np8rdmVKRdrRVW/YPLAQNWLPNLZrtKsZe4ZDkZtz/+Yg7Zqe+0IqchfujpNycCAEPiwv8y+jIyOYjFSEO2bG5Nw6fzyfOUuqUqnwN7VaDSVGKjIZcufp9BkIDh6QO0ecd9UNtvaa1FoFCrmI4x3i6lABmUhFLkcmQgAXhxyCMzw8zNuP69TVxeWhE0S5LFu7B+zst1j6XEMyUhnJkIzEDJMhGUpEtVrlR7NIudTJTiuwuWckIctgXzfbzYAkUlESx5gZM8++88fcZIlUrXlAPp8jaSXIoZU4xShL4hFJnJCK3IQkntzpA5yUJFKdhRwHsREbJHJSHqBRr+MSqUgSkgMiAM6RcvkcvzZj9ptGd0eeljlRJrC5HeM/1zifXSMVxmcXF0unTk7IDJkwMyRhcUwuH9Hf18PoxdPIYbfWwrZWqPR+Y/fT++Tr6tLt4O604/WDG3f7B/of9Q5d6VldetMI7k67Xk5duDVQvvRCSjp+A5XMMGcdZp9aAAAAAElFTkSuQmCC",
	},
	{
		id: 9,
		first_name: "Bradly",
		last_name: "Drogan",
		photo: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAIESURBVDjLpZM9S9ZhFIev/+Pz+BKGUBAZJUQEOqRCi6hDYAYtfQdXySki+wYubeHQEoHQWoRLYBGIOFhkJTg0tCShgfq83W/nPqfhH9SgIXTGM1zn+p3DKcyM/6nqUc3553uWVYkCOStRlCDKs9lLxYkAISqTg6cQVdRAsvFyo35yA5eUmJW9QyFlo6+ng6bTkwNaToii+KRINkK1QsPJ0QB7eL/coiqWBEtC+/IDQjR8MpIo3bVM3ed/GEzdBFWKnClyprGpBDFcyKQMPTWjflwES0IhAh/egyQYHqXpIj4p7VhG8J0F4tIxgBBLQPCQBJJwGIyQFBeUlI3eLkVbytzKjKUoxJhIPhFCoqI+gAj4AN5DjDRbio+Gi6WFT8ZQ/xqdXzO23UC29xnQAXzLU1X3e3IIECOIIC6VBlGJ2QjtZW5MbHB9aIyLZ67ydusF619WONgVKtoup+JcaZAS2lJ8LAEuKu3GC0YHR8iVzEj/NLlIjF0bJzYOqVjb/RWhjKNOcekPYL/5g1rRy52hOQDuTT3hyrlhMKOqbQcpwfgE5AwimBOiGOf7aojC928HbO2ssbmzyvz0UxZez9Dd0VVe4VHXLXRpFwuCpYyljPZmlpd2ICqWhbMDt1n/9Ibx4UlefV6ks6iy+vEd9Z9DFCd957G7FxaAWeA00AAW1x/vzP8Cqr99v3YC63EAAAAASUVORK5CYII=",
	},
	{
		id: 10,
		first_name: "Marget",
		last_name: "Dungate",
		photo: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAKVSURBVDjLfVNLSFRRGP7umTs+xubpTJQO4bRxoUGRZS1CCCa0oghatkpo4aZN0LJttIhx1UZs0aZ0UxaKgSFC0KZxhnxRaGqjiU7eUZur93n6z5lR0Kxz+e93H+f/vu//zzkK5xz/G+l0+rlt23csy1IJQSjDNE2BL5V/EWSz2SAl9IRCoduVlT4YlATXhZxNOeFwCMPDQ1APS85kMu0iORqN1tfU1OD7/BKEuutyuNwlIg6HyAzDgDo9PW04jlNBISft2hSoadpBy1hf14jIRfJKh/ymiuR4/AQKhQ2pzsXFhUsuQ7yQJiLhIN4OvEFT8xmpLv5JB4JVJD/sSdM0BYpC99JNooitzU08uXdOKo6nP0G4PX7tZsmBsCpUxcRwpBaMMSgUrBziWRBwx0WD8xGJBEPeaQQv94AJB9QTImDweDz7gpVRjsUBtLREcDLZhWOBLJzVdMmBVV4ehSnwqOqeukRRAuGFQAZR308EG5MoLgwhGCAHc68R2vZCFSyiIaIEoZg46pP1l4aC5Q0bTZFlBE9dh6NPoioax46TQ92lJiQ3xkoErFyniNmvf++LhmgAljZPAnlyVERFIA/s6Ciu7JQIvF4VjztPy+WxLBu6bpArF9VWDuGtQXirXbj2JJhbAJgf3DIx0zeHd7k4VOrk09HRD227G4Uw4vf7E7XWFHyY4HUdtxRuvofibGFiUIfXKMJDJaqtD7CyOIJ9Z6G7u/s+kdw433rxcrzQi/qWNpj5Z1DVICZGdAxOxqCxGO0DG9s2xH6Y2TsLqVQqRkuWam+/iiN+P5heAcWzBE9lDFPDv35/GV/tetQ79uJgf/YIyPo6xef+/ldnRSmNVWto/rGAoqabudm1zru93/oOO3h/ANOqi32og/qlAAAAAElFTkSuQmCC",
	},
	{
		id: 11,
		first_name: "Isa",
		last_name: "Springtorpe",
		photo: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAANsSURBVDjLdZNrTNNnGMWb+M3wRaObc/plSxYToiZzc94quCGCVRfMnwYtUiyuUhUQKqtcCmhatAgEaKlcBNFSBYQ5lSIgQ0GFttRCL0BBoAVUVFKo/UtLBXJsiZp5+3CS98N7fjnnyfNQAFDeK1uf6nVGm5CSquS28VqPzMY0RcweVjDawmqC+QevZi6IvfJk4f//e/ThkalL8RFqTg7dHqhFo6UJiuEGdLzU4oq2HISMJo0pH+VwLpqHIgoHfD4DZHQlB1V0l+GOpRFl/VdxXMsH91Eqavr+xd5LO62MkuIfI0vN1tLWcXAvD4IQ6YI+AESdyYtPq0+QzcPNEBklYKmjEa6KxvmeUkhbxNgh3cZhXxiSZteOQWEgUXDnBWhpHeR23sPF8wB3X4Gi/xaKTJfBVEchpI2NeE0aZFoZ/MU+naxC489h4r7Zmzo7shrGUaWy4fgFE6hRTYJ5QHxLZGe9uRFRmkTsc5vZyjjI+isQVREJavpvWw7kme5nK56hWmODpPIaTPIQPL4hRFeJP3T53mGUo/XhrhuWOsRokiDS56Gyrwbn6kXYJPi1hJHbS3f3dVQqJ1FcXYaxZh5s+lqAfIpJfTXaMwOeU8Kv023K52pc67sOyd08+GZtsm48/UtKfeypJbnx5cvcffU1dXKMG9PgGr2JsXvn4DD8g1nLAxgusp0Uunx3p/hujqfvS5+MDXGKWGLlNJOZ5AymW6doe1bzMnLMViMfc44HcAweg9U9p15ZBJTSgzPqvKCfKLuK/Lh+uVS2IZ71vYv9V9Z0aChJpiTjdcg+jGZ6cyYMCZhztmNqgAnnCAP2nkTo82kgGAnF80Oc+fvEojfHjha6WCzXa6EAkxUyWOVlGGRuwVgH7505DM7h/XhlTEK3JBB+BH/qO9+MpfOAN0c4S92RSXthPiaq5Hh2Kgn94mj0KuLcsVvhNEdgeuQAbO4kPZIA+IcWYNnWs8RHm+jYSxAki4WJVD406Wx01yVCdzsHT1TBmDIzYO06iUc5NKzfnTbyLTU94Iu3YN/su/3Vug1DVaI/ALsFpiICzYnL8bAgELX8za4/6dzz31CFXl89Jo8mVq3xEhzynnO1S+BS5UIl3IaqQyvIhoQ1az81fhHgUTB1kfMMc9XMf2cDZ5qyfm+5xVv9w9fMHr0Fh4yy26byoRwAAAAASUVORK5CYII=",
	},
	{
		id: 12,
		first_name: "Melly",
		last_name: "Mossom",
		photo: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAIESURBVDjLlVJtaxpBEH7uvNSL50skFBqCiDVYpCWiIAjtx4Ih4I/zs78jkD9QioVAUBGNWigqRfpBxSO+3LnbmY13mNQWOvAwuzszz7zsQEoJBomWzWY/V6vVb5lM5oruBr/tYBQKhU+1Wu0r+/CbF6cOA02Tv9jr5gbn+TyGd3cQlQpe40nYFry9xZvLS/y8v8fm+lrZ0lJqukbCTlYwCCsWw3a7RTgex3EggLiuK5jkYkYiynYcjcLcEXOsvjvDNAx0BgPl1O31IIjEPjmBHQ5ja5rodLvK1nl48Ang9dgHRIyyN87O0LNtXFD2FLWmU4B0HKxdF99JDwhvhUCB9CPZLwDd2K/gw+kp3lsW5GYDl5wEg8heEdG7oyNkSGuE4GKBRyL1q6jX69J13b/CcRy5XC4VWPiNYzjWwAFZr9dot9tIp9Po9/uq9/l8jnK57H25L/ohAg4ejUaI0ORzuRxSqRRCoRAosw+P6BmB95inXfAWhdFqtVQ1Dg+UqqNW/Jg/WnhZ4mw2g6DJc/BkMlFnhud3cAb7ZNwOrbaaQzKZ5OXBcDiEQb/GA9XljoqU2A+u0CqzqVgswqKv5awcPB6PfSJ/Bgv6V5uEjoIN+wjQHrDmCjhzIpHAarVSLfktdGlNyTHKZf1LvAqYrNlsolQqPRFMp9MvjUbjI/5D6Dd+sP4NLTpNB1cxufkAAAAASUVORK5CYII=",
	},
	{
		id: 13,
		first_name: "Georgy",
		last_name: "Iannetti",
		photo: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAJGSURBVDjLzZNvLNRxHMc96qGt5z3uH3FIrEUlItel5VLtbjbcdJhwOBoiRpy1zhNNVsymKbNl0mit0hbTH0dDtRul21iju19cjuN+r760tbWVZT3pu70ffl7f9+fzeX+8AK9/kdf/AZAL8zRCX4SkNXnyDZLHkCWtZmZIK2nnJbcuRVpOTJSWzp6TXPFqafF43Jzz6DHtOkAUJAg56e2B/n4YGQGrFcbH8Tx9wlxrK/aWFuabm3E03MBmNmO7VMpC+BHnfGhY3BrgFTcboasL2tthbAyGh6GjA09+Pja9nmmdDntKCrNaLa9VKt5VV2PNykEKCLGtAfxlo+GFXFMF4jemplh/fX141GqmlUo+x8byVWgyIoK3lZVM6lKR/PfNOHYFJKzPQPS7VfT7wFNWCp2dMDMDFguyRsOcKHQIiCMmhomqKj4k6ZD89k7YdyoUv2xhRZ/q7U5O7l4tKICBARgdRRbWpagonNHRzJpMfBTFDt+gCfsOvz2/XeOSRuvtSjjT7TYW/ACIGTgPH2aptpZPmVmUN6rd+junhzbMweKJk97fYpQPl4tKkNvaWKmpwZ5toKRRLRc/zqWw9wLxt5SWDYO0cChy2/z+g9ZlQx5OgwFjU7xc9CiHpjf1NFjqSLubRGRt2PvwipAtf0yYFBjqI/odrLimciXePuXOvZ/O9SEzdS9NZN9L50BZsCv4oqLnryOrqo/CPFiNqb+c2KuRBBn9Qjd1C8IyV55fpvxZMYF5vmz6mIRlhGUUOT7sztj+E/Ad9GPLsXC6ErkAAAAASUVORK5CYII=",
	},
	{
		id: 14,
		first_name: "Otis",
		last_name: "Soares",
		photo: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAQAAAC1+jfqAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAD0SURBVCjPfdExSwJxHMbx/yTc1NrUy+h1+AZ6GUJBaYdiKVwopjmYOASiINJgEVFwUFHo4BIiDtql/SPU5BDUQb8Nomh3J8/we4bP8MBPIOYpexdtPcvyyrO6ETxR5zGwAeiMeOfmxBE8MOKXKsWwA7hjSJceZbJhW1DC5BvJDy+kNRtwzYA2BgYSnUTEAgr0+aBJC0mbe85i/0AOkw4Gn8SH0Yo2CRGMrYEralyOq/SJzrRtBEJVvMoKyJCSyd3zZh2dUMZmZOotuYOIuAuYBKbqlgVcKPN7KhvccnRsAYv49/I0ODA9Lgfgcx1+7Vc8y8/+AURAMO9/VDEvAAAAAElFTkSuQmCC",
	},
	{
		id: 15,
		first_name: "Natalya",
		last_name: "Snazle",
		photo: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAQAAAC1+jfqAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAACWSURBVCjPY/jPgB8y0ElB+YHyA8UTcg+kLYjfEP4Bm4ILxQa5Dqn/4xv+M/hdcHXAUFAc8J8hzSH+fzhQgauCjQJWN8Q7RPz3AyqwmWC0QfO/wgKJBWgKwh0C/rsCFRgBTVP4/59BMABNgZ+Dx3+bBghb4j8WK1wdHP4bQRUIYlNgs8DogOYGBaAPBB24DrA40Duo8UEA+kT4W+XS/8wAAAAASUVORK5CYII=",
	},
	{
		id: 16,
		first_name: "Dannye",
		last_name: "Lionel",
		photo: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAHXSURBVBgZpcE/S5VhGMfx7+8+T2VI0SDVKvYSrKE/0FsIwgZpCFqiISSiIcoigkIosWyrOR16AU2NETSGZUQoiNYgBJ1znufcz3VdeUvOIX4+igj2QhemFq6fPT/+ZLMXwxGAO+GOuREeeDhhhkcQZpg7h/fn7tLS2u23Tyfmq/Ez43P7hobTsSF2Y7jbszlgvurlSL3NP+xWP0diSxUWPJo8wW5dfbxCUUU4xaA1AggPzMEJ3ANzx9rA2sDCGVgwevwQ5kZREUGhJBRBJBEK5CIlISUkQ52g44mqDQpvjaIyN4oEhASCToAL3INOQFKHSmAKLDmFm1NU4cE2CSJIQEggkCAscMHsp4d4G9w4eY/C3SiSu7FDEkgUCUgSqhIzH+7SH3TpNr+ZfjdF4e4Uqc2ZbRKSKCSBhHnL/fc3yblhbGSM0aNj1LnLlVeT5NxQpDCn6AACJCFAwPOPM/zcXKeuG+p2QN02HNh/kNWNFX6lBYrk7uwQkIAk0ZG4dfoOry++YXn1G02uaXLN8vdlZi+/ZCRfoqjWfqwsXnuWJ9wMN8fMcHcsZ9wdj6B/pKbfNmTLbKxvMD37hS2LbFFE8D/nHpyKpsnkOjMYZD6/+Cr+UUSwF38B/pkb32XiUiUAAAAASUVORK5CYII=",
	},
	{
		id: 17,
		first_name: "Reece",
		last_name: "Leggat",
		photo: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAGNSURBVDjLpVM9SwNBEJ297J1FQBtzjQj2dgppYiP4A1KZRoiFrYWt9rHyH6QUPBDTCimtLNSAnSB26YKg4EdMdsd5611cjwsIWRhmZ3f2zZuPVcxMsyx9fPF0NRfS2vM7lx2WtcQiJHvDRvZMluXMGNHstJH7+Wj09jHkOy1+tc3VxeC+P6TXT1sYZX2hT7cvS6lepv3zHUp2T8vXNw81dXT2yGwEGeERSbSVCC5qysYa+3vm9sJGmLFojceXJ9uklCqUIAic5G3IytahAAhqqVSiwWDwx6nogW9XKhWphaGAvC50Oh1qtVr/7oAdCwBQwjB00mg0qFqtUr1ed3YURZM7X7TWTqM2Gm3CASRJEur1etTtdp1DnrafFtJGMbVNGSBas9l0DrAzR6x8DdwASUB0RqNNGS2/gH7EInvCwMhkZTnlnX0GsP09tJER0BgMoAEAa1rETDIQvBkjBZeHMIjjuNB5Ggg0/oZWPGrHGwd7Fp9F2CAlgHKqf0aYXb6Y2mzE8d/IfrXVrN/5G81p6oa2mIEUAAAAAElFTkSuQmCC",
	},
	{
		id: 18,
		first_name: "Merrill",
		last_name: "Raddenbury",
		photo: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAALvSURBVDjLjZNvLNRxHMd/Tft50AOPqs1aTapJzp/8yYWaLM5k+a/F1F3FxXAidnIc53adc7j5uxo6OqX8PxcdZY7uWhJRD1pqrXWPCveb8ATvfq4yitaD95Pvvq/X5/t9f/clABCbpSv5CEnHWsNjWGvSncit9m0Jq3kMoybdcbEny3lRm33UqM11I/9LoE5xIGnQpJOxMN6UiNfNKdCXh6Kv4Jipr9CT/KdAk+ZI9mQ6m4bkQZh4wKOThomWDEy2Z2O4Ogo9BUyTJo9JbirQZDiRvXwXargsGJMP06GvCINW5IXHYm/oKqMw1iJAvyISLXxP6l66B7lB0JvlQmqzXSm9IgSTrZnQV0agX+qLt28mzOkW+aJPHgmDKgtdhSGo47pSVRddzBKCLoikC6L05WGYbOPTR42EVnIKL0deYHp6GrOzs5h6/w5NmT5oEwbjya0kNFwPgCyCQRWesSeJvjwPg74y/Nc9o2nYD+Njo5iZmTHDv5Oq8sGVehfUXvNDZ3EsKi57I9v3kIGgm2VpC5nLuqpoqIUnVj59nFqbvD7cBk/kq88jusYOJWwm+CcOLtNh/Swwj8nqyPUcUpTKVxYWFtYmJjceQ4LSDexaZ+S0R+LBiAIZD8/idMlu8AL3h/71jDKZbI6iKLMgiYY7XlWhdbTCDN4fKUNZfwaUhiJwVf5wl1guM0TbrDYIxGLxnMlkMgsu0fddhUu0qZD2JkH8KB5CNRsFmgTU6ESIveONg3nEEpH8lO3I6TwXE6UM7o+ShyzdHWzAqiTm9mE0vyiD6rkcSn0R6p7dpCWJqNYVIF7Fgm2uxTxDsC+NoOEvvO54CAauIbmbA44iDkajEaHVNghU7IFf6S54yawQV38cVYNCcBr9YSfagfDaADjx7L8T9OSBQIXvZy+hu+Ekz4sKvhr0lcvlfpBIJJBKpaB7QXFxMRzyt69cUPrBNsdyxV3gMEHD3w5cshkgtvqmf8ZGQMzvvWGBnXzCZv36D8sKlHMs9WAJAAAAAElFTkSuQmCC",
	},
	{
		id: 19,
		first_name: "Leonhard",
		last_name: "Colter",
		photo: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAIaSURBVDjLhZFNaBNRFIWzFdy6EIrQhdCFLlyJ4KLgShFciOhGTbVVUFf+rNxYEFPtmPhDKWI3diP2DxJF22mfirQrMVpbRKUohJi0SUwySZvJzBhOz32dlCimHfh4897cc+67ZwInQuNtJEyiTXhGzpIdAAL/EuCH+69nk6pUcZVVZ2WNIlkqVtTo9IJi3Y1mBjERF1icX3bV77KrsmVHZUuOWrKq+jyRKYvBYFMD6SrijC9aLFZVqmCrZN7W+80MnpdsV+XYWYrTFL+dS72/8ng6f8qY9E4TWVnnkIJPktyS/MRgJEeh49W0WDpfG5gpMhdwNFh1VtZgLmAuYC6gdlQMOo2RuIovZJT7p6Y52Wt6Iub84GhgLmAuYC7gLfU5xxKD73oOvuwnQ5L4xIeEzOtJVxFnfBFzAW8H5qL3fxn4Jk/MuBaPkzJzAXPRxekGcSJXQZrvv3LLYvCzLt4iYZJXZCdZZC6oerV18fC7IVx9FMS58BF03DmE/thDMfhaN9hGukmrvw+Fxz5i9kcWzATRmafoHu7Ai/k+fEqZiExewPHIbrRfPvAm8L9/65scI5ZVcRC8fRDRuXuIfnkAeYypLkSmzmPvxe12UwPf5BtzweHre/ByfgCNT+xznxhsKN4qNyD2vkstTq95BiEzqMWhieDmN6CwhYyRXSy8edRow12zU3eWVfY8NzYcoREW95CSXNtfe+R8FZtm9C3KURomAAAAAElFTkSuQmCC",
	},
	{
		id: 20,
		first_name: "Jackquelin",
		last_name: "Bemwell",
		photo: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABGdBTUEAAK/INwWK6QAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAHMSURBVDjLpZNNS1VRGIWf67HQ8MZVEtLserNIsk8JA0f1F8JZ/oRG9RNs3ESaNQscFQ5KCoIiopGhhFDgHZRgZEIRSN3PvddqcI5memZu2Ow1WQ/Pu+Et2OYgp4MDns7tcH9hY0dFNgZiBAyWkCEqzVFw71a58B8AYLD3MMZYYMAyMngXRILPm7X9BtHGzgoC29iZTQaSjGRiO2eEGFNFbKSsuJ31P6Qdtf8THZXSBVFC0Sk0iv7CCtcOPSDxFlEmhBxAEFlJmU2aC7HBaZ4zXBmn4tcoGgXvBygoK21D0nzSbxgsT3B0YJyB8I6euEbIA4TAv6JMiKJbGwyFVxSPlYhbTxm6NM1IWEBq5wBizMrp/I6i3HrB4NhNaCyz+GiOnlKdvvoSw8lKnkGqrAAxmlL7E2f6GhR7a6j5BSzi7/ecunGXiWSexdnJ4h6DTF1GsU2l9phS+QqqrWDVuTo1ilrf6Oqqcu7ydUKzdWePgVhdr7G6/ofk+0sqI+c5UvyBw08oJCzNVwGhRpX+s8PEZvv225kLY4W8bVycnfx6cXruRNKxhsOvdCF2TZ10j7L58QPVZzNPOvM2LDRbXcsPp+qWsbTreudFwvbxvyYHcoBEg0hXAAAAAElFTkSuQmCC",
	},
];
