import { useEffect, useState} from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import logo from "../../images/logo2.png";
import sidebar from "../../images/sidebar.png";
import { PiSquaresFourThin } from "react-icons/pi";
import { GrDocumentText } from "react-icons/gr";
import { FaBell } from "react-icons/fa6";
import { IoMdSettings } from "react-icons/io";
import { NavLink, useNavigate } from "react-router-dom";
import errorAvatar from "../../images/avatar.jpg";
import "./Header.css";
import NavDropdown from "react-bootstrap/NavDropdown";
import {
  BASE_URL,
  SERVICES,
  SERVICES_NAME,
  VERSION,
} from "../../Constants/Auth";
import { USER_ID } from "../../Constants/User";
import { messaging } from "./../../services/firebase";
import { getToken, onMessage } from "firebase/messaging";
import { ToastContainer, toast } from "react-toastify";
import {
  osVersion,
  osName,
} from "react-device-detect";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import {
  setAcitveNav,
  setAvatarURL,
  setFavoriteLenguaje,
  setFirstName,
  setLastName,
} from "../../redux/features/userSlice";
import { v4 as uuidv4 } from "uuid";
import { Get } from "../../Apis/Members/Models/Get";
import { GetAvatar } from "../../Apis/Members/Models/GetAvatar";
import { GetByMember } from "../../Apis/Accounts/Models/GetByMember";
import { SaveDevice } from "../../Apis/Members/Models/SaveDevice";
import { dataInfo } from "../../pruebasJson/data";
import { IoChatbubblesSharp } from "react-icons/io5";

const Header = () => {
  const [show, setShow] = useState(false);
  const [bgColor, setBgColor] = useState("home");
  const [avatar, setAvatar] = useState("");
  const [userInfo, setUserInfo] = useState();
  const [memberInfo, setMemberInfo] = useState();
  const [toastShown, setToastShown] = useState(false);
  const navigate = useNavigate();
  const baseUrl = BASE_URL + SERVICES + "/" + VERSION + "/" + SERVICES_NAME;
  const currentUserId = USER_ID();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { favoriteLenguaje } = useSelector((state) => state.user);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const replaceImage = (error) => {
    error.target.src = errorAvatar;
  };

  const logout = () => {
    localStorage.removeItem("User");
    localStorage.removeItem("AccountId");
    window.location.href = "/";
  };

  /*Get user info */

  const getUserInfo = async () => {
    try {
      const classRef = new Get();
      await classRef.List({ MembersId: parseInt(currentUserId) });
      let result = classRef.ResponseBody;
      if (result.MemberInfo) {
        const info = result.MemberInfo;
        setUserInfo(result.MemberInfo);
        dispatch(setFirstName(info.FirstName));
        dispatch(setLastName(info.LastName));
        if (info.SettingsPreferredLanguageWeb) {
          dispatch(setFavoriteLenguaje(info.SettingsPreferredLanguageWeb));
        } else {
          const userLanguage = navigator.language || navigator.userLanguage;
          const languageCode = userLanguage.startsWith("es") ? "es" : "en";
          dispatch(setFavoriteLenguaje(languageCode));
        }
        getAvatar();
      }
      console.log("Result api", result);
    } catch (error) {
      console.log("Error", error);
    }
  };

  const getAvatar = async () => {
    const classRef = new GetAvatar(currentUserId);
    await classRef.List();
    let result = classRef.ResponseBody;
    setAvatar(result);
    dispatch(setAvatarURL(result));

    // console.log("Result avatar", result)
    // let config = {
    //   method: "get",
    //   maxBodyLength: Infinity,
    //   url: `${baseUrl}/Members/Avatar/Get/${currentUserId}`,
    //   headers: HEADERS(),
    // };

    // axios
    //   .request(config)
    //   .then((response) => {
    //     setAvatar(response.data);
    //     dispatch(setAvatarURL(response.data))

    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  };

  const getMemberInfo = async () => {
    try {
      const accountID = localStorage.getItem("AccountId");
      const classRef = new GetByMember();
      await classRef.List({ MembersId: parseInt(currentUserId) });
      let result = classRef.ResponseBody;

      setMemberInfo(result);
      if (!accountID) {
        localStorage.setItem("AccountId", result.AccountInfo.Id);
      }
      console.log("===>>", result);
      console.log("==>>==>>", result?.AccountInfo.Id);
    } catch (error) {
      console.log("Error", error);
    }
  };

  /**firebase  */
  const activarMensajes = async () => {
    const token = await getToken(messaging, {
      vapidKey:
        "BBCvdIf1vSvAMD1G5RMXxbnrqRLuNg4Adi5UQOvijPcU3msMIKrlPg7pquv-Ar_ZBOBUGpvsHJv-8YfTM8_BlSA",
    }).catch((error) => console.log("Tuviste un error al generar el token"));

    if (token) {
      setDevice(token);
      console.log("tu token:", token);
    }
    if (!token) console.log("no tienes token");
  };

  const setDevice = async (token) => {
    const deviceGuid = uuidv4();
    const deviceUser = localStorage.getItem("deviceId");
    if (!deviceUser) {
      localStorage.setItem("deviceId", `${osName}${deviceGuid}`);
    }

    try {
      const classRef = new SaveDevice();
      await classRef.List({
        Id: 0,
        MembersId: parseInt(currentUserId),
        DevicePlatform: osName,
        DeviceToken: `${token}`,
        OSVersion: osVersion,
        // ModelName: isMobile && isAndroid ? "Android" : isMobile && isIOS ? "Iphone" : isBrowser && !isMobile ? "Desktop" : null,
        ModelName: "Web",
        AppVersion: "1.0",
        DeviceId: deviceUser ? deviceUser : `${osName}${deviceGuid}`,
      });
      let result = classRef.ResponseBody;

      if (result.MemberDeviceInfo) {
        console.log("Device save");
      } else {
        console.log("Error saving device");
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  // useEffect(() => {
  //   activarMensajes();
  //   onMessage(messaging, (message) => {
  //     console.log("tu mensaje:", message);
  //     toast.success(message.notification.title, {
  //       onClick: () => {
  //         navigate(
  //           `/view-document/${memberInfo.AccountInfo.Id}/${message.data.documentId}/false`
  //         );
  //         console.log("Account id", memberInfo.AccountInfo.Id);
  //         console.log("Document id", message.data.documentId);
  //       },
  //     });
  //   });

  //   console.log(isMobile && isAndroid && "Android.");
  //   console.log(isMobile && isIOS && "IPhone.");
  //   console.log(isBrowser && !isMobile && "Desktop");
  //   console.log(deviceType && `Device: ${deviceType}`);
  //   console.log(osVersion && `osVersion: ${osVersion}`);
  //   console.log(mobileModel && !isMobile && `mobileModel: ${mobileModel}`);
  //   console.log(browserName && `browserName: ${browserName}`);
  //   console.log(browserVersion && `browserVersion: ${browserVersion}`);
  //   console.log(osName && `osName: ${osName}`);

  //   console.log();
  // }, []);

  useEffect(() => {
    activarMensajes();
    onMessage(messaging, (message) => {
      console.log("tu mensaje:", message);
      if (!toastShown) {
        const accountID = localStorage.getItem("AccountId");
        toast.success(
          `${message.notification.title}${message.notification.body}`,
          // {
          //   onClick: () => {
          //     navigate(
          //       `/view-document/${accountID}/${message?.data?.documentId}/true`
          //     );
          //   },
          // }
        );
        setToastShown(true);
      }
    });
  }, [toastShown]);

  useEffect(() => {
    getUserInfo();
    getMemberInfo();
  }, []);

  const userAvtar = (
    <>
      {avatar || user?.AvatarURL ? (
        <img
          height={40}
          width={40}
          src={user?.AvatarURL ? user?.AvatarURL : avatar}
          className="user-avtar"
          onError={replaceImage}
          alt="Admin Panel"
          // style={{border:"1px solid white"}}
        />
      ) : (
        <img
          src={errorAvatar}
          alt="user"
          height={40}
          width={40}
          className="user-avtar"
        />
      )}
    </>
  );

  return (
    <>
      <Navbar expand="lg" className="bg-header">
        <Container className=" header-container" fluid={true}>
          <Navbar.Brand className="header-brand">
            <div>
              <img src={logo} alt="logo-app" width="144px" height="44px" />
            </div>
            <div>
              <img
                src={sidebar}
                alt="logo-app"
                width=""
                height=""
                onClick={handleShow}
                style={{ cursor: "pointer" }}
              />
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <div className="nav-container">
              <NavLink
                to="/"
                style={{
                  color: `${
                    user?.ActiveNav === "home" ? "white" : "lightgray"
                  }`,
                }}
                onClick={() => {
                  setBgColor("home");
                  dispatch(setAcitveNav("home"));
                }}
              >
                <PiSquaresFourThin width="19px" height="19px" />
                {dataInfo[favoriteLenguaje][2]?.home}
              </NavLink>
              <NavLink
                to="/Docs"
                style={{
                  color: `${
                    user?.ActiveNav === "docs" ? "white" : "lightgray"
                  }`,
                }}
                onClick={() => {
                  setBgColor("docs");
                  dispatch(setAcitveNav("docs"));
                }}
              >
                <GrDocumentText width="19px" height="19px" />
                {dataInfo[favoriteLenguaje][2]?.docs}
              </NavLink>
              <NavLink
                to="/chats"
                style={{
                  color: `${
                    user?.ActiveNav === "chats" ? "white" : "lightgray"
                  }`,
                }}
                onClick={() => {
                  setBgColor("chats");
                  dispatch(setAcitveNav("chats"));
                }}
              >
                <IoChatbubblesSharp  width={25} height={25} />
                {dataInfo[favoriteLenguaje][2]?.chats}
              </NavLink>
              <NavLink
                to="/Alerts"
                style={{
                  color: `${
                    user?.ActiveNav === "alerts" ? "white" : "lightgray"
                  }`,
                }}
                onClick={() => {
                  setBgColor("alerts");
                  dispatch(setAcitveNav("alerts"));
                }}
              >
                <FaBell width="19px" height="19px" />
                {dataInfo[favoriteLenguaje][2]?.alerts}
              </NavLink>
              <NavLink
                to="/Settings"
                style={{
                  color: `${
                    user?.ActiveNav === "settings" ? "white" : "lightgray"
                  }`,
                }}
                onClick={() => {
                  setBgColor("settings");
                  dispatch(setAcitveNav("settings"));
                }}
              >
                <IoMdSettings width="19px" height="19px" />
                {dataInfo[favoriteLenguaje][2]?.settings}
              </NavLink>
              <div className="dropdown-header-container1">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: "2px",
                    marginTop: "10px",
                  }}
                >
                  <h6
                    className="dropdown-header1 "
                    style={{
                      marginRight: "10px",
                      textAlign: "right",
                      fontSize: "0.80rem",
                    }}
                  >
                    {dataInfo[favoriteLenguaje][2]?.welcome}
                  </h6>
                  <h6
                    className="dropdown-header2"
                    style={{
                      marginRight: "10px",
                      color: "white",
                      textAlign: "right",
                      fontWeight: "bold",
                    }}
                  >
                    usuario
                  </h6>
                </div>
                <NavDropdown
                  style={{
                    marginRight: "3px",
                    color: "rgba(0,0,21,.5)",
                    fontSize: "20px",
                  }}
                  align={"end"}
                  title={userAvtar}
                  className=""
                  id="basic-nav-dropdown"
                >
                  <NavDropdown.Item
                    onClick={() => {
                      navigate("/Profile");
                    }}
                  >
                    Edit Profile
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={logout}>Logout</NavDropdown.Item>
                </NavDropdown>
              </div>
            </div>
          </Navbar.Collapse>
          <div className="dropdown-header-container2">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginRight: "2px",
                marginTop: "10px",
              }}
            >
              <h6
                className="dropdown-header1 "
                style={{
                  marginRight: "10px",
                  textAlign: "right",
                  fontSize: "0.80rem",
                }}
              >
                {dataInfo[favoriteLenguaje][3]?.welcome},
              </h6>
              <h6
                className="dropdown-header2"
                style={{
                  marginRight: "10px",
                  color: "white",
                  textAlign: "right",
                  fontWeight: "bold",
                }}
              >
                {user?.FirstName ? user?.FirstName : userInfo?.FirstName}{" "}
                {user?.LastName ? user?.LastName : userInfo?.LastName}
              </h6>
            </div>
            <NavDropdown
              style={{
                marginRight: "3px",
                color: "rgba(0,0,21,.0)",
                fontSize: "20px",
              }}
              align={"end"}
              title={userAvtar}
              className=""
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item
                onClick={() => {
                  dispatch(setAcitveNav("settings"));
                  navigate("/Settings");
                }}
              >
                {dataInfo[favoriteLenguaje][3]?.editProfile}
              </NavDropdown.Item>
              <NavDropdown.Item onClick={logout}>
                {" "}
                {dataInfo[favoriteLenguaje][3]?.logout}
              </NavDropdown.Item>
            </NavDropdown>
          </div>
        </Container>
      </Navbar>
      <Offcanvas show={show} onHide={handleClose} className="offcanvas">
        <Offcanvas.Header
          closeButton
          style={{
            borderBottom: "3px solid #06B576",
            backgroundColor: "rgba(5, 114, 210, 1)",
          }}
        >
          <Offcanvas.Title>
            <img src={logo} alt="" />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: "0px",
          }}
        >
          {/* <div className="canvas-title">Welcome</div> */}
          <div className="canvas-body">
            {/* <div className="canvas-body-name">{userInfo?.FirstName} {userInfo?.LastName}</div>
            <div className="canvas-body-account">
              {userInfo?.UserName} ({memberInfo?.EntityTypeInfo?.Description ? memberInfo?.EntityTypeInfo?.Description : null})</div>
            {memberInfo?.AddressInfo ? (
              <div className="canvas-body-direction">{memberInfo?.AddressInfo?.Street}, {memberInfo?.AddressInfo?.City}, {memberInfo?.AddressInfo?.Location} ({memberInfo?.AddressInfo?.State})</div>
            ) : null} */}

            <div className="canvas-ul-container">
              <ul>
                <li>
                  {dataInfo[favoriteLenguaje][3]?.canvasText1}
                </li>
                <li>
                  {dataInfo[favoriteLenguaje][3]?.canvasText2}
                </li>
                <li>
                  {dataInfo[favoriteLenguaje][3]?.canvasText3}
                </li>
              </ul>
            </div>
            <div className="canvas-info-title">
              <div className="canvas1">
                {dataInfo[favoriteLenguaje][3]?.canvasText4}
              </div>
              <div className="canvas2">
                {dataInfo[favoriteLenguaje][3]?.canvasText5}
              </div>
            </div>
          </div>
          {/* <div className="canvas-footer">
            <div className="canvas-footer-button"  onClick={() => {
                  navigate("/Settings");
                  setShow(false)
                }}>
              Settings 
              <IoMdSettings width="25px" height="25px" color="gray" />
            </div>
            <div className="canvas-footer-button" onClick={()=>{
              logout();
            }}>
            <span style={{color:"#06B576"}}>Logout</span>
            <MdOutlineLogout width="25px" height="25px" color="gray"/>
            </div>
          </div> */}
        </Offcanvas.Body>
      </Offcanvas>
      <ToastContainer theme="colored" position="top-right" autoClose={2000} />
      {/* <button onClick={()=>{console.log("prueba",user)}}>Prueba</button> */}
    </>
  );
};

export default Header;
