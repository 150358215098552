import React, { useEffect, useRef } from 'react';
import './ChatsView.css';
import moment from 'moment';
import profile from '../../images/profile.png';

const ChatsView = ({ chats }) => {
  const chatEndRef = useRef(null);

  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [chats]);

  return (
    <div>
      {chats.map((chat, index) => (
        <div key={index}>
          <div className={`d-flex align-items-center ${chat.outgoing ? "justify-content-end" : "justify-content-start"}`}>
            <div className={`${chat.outgoing ? "chat-outmessage-container" : "chat-inmessage-container"} d-flex gap-2`}>
              <div className='d-flex flex-column gap-2'>
                <div className='title-sender' style={{ textAlign: chat.outgoing ? "right" : "left" }}>
                  {!chat.outgoing && (
                    <img src={profile} alt="Profile" style={{ width: "30px", height: "30px", borderRadius: "100%", marginRight: "5px" }} />
                  )}
                  {chat.sender}
                  {chat.outgoing && (
                    <img src={profile} alt="Profile" style={{ width: "30px", height: "30px", borderRadius: "100%", marginLeft: "5px" }} />
                  )}
                </div>
                <span>{chat.message}</span>
                <span className='title-time'>{moment(chat.timestamp).format("HH:mm")}</span>
              </div>
            </div>
          </div>
        </div>
      ))}
      <div ref={chatEndRef} />
    </div>
  );
};

export default ChatsView;
