import React, { useEffect, useState } from "react";
import "./Chats.css";
import { USER_ID } from "../../Constants/User";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { hideLoading, showLoading } from "../../redux/features/alertSlice";
import { Get } from "../../Apis/Members/Models/Get";
import { GetByMember } from "../../Apis/Accounts/Models/GetByMember";
import { dataInfo } from "../../pruebasJson/data";
import ComunicationCard from "../../Components/ComunicationCard/ComunicationCard";
import Backdrop from "../../Components/Backdrop/Backdrop";
import profile from "../../images/profile.png";
import document from "../../images/document.png";
import chatProfile from "../../images/chatProfile.png";
import { FaImages } from "react-icons/fa";
import { IoDocuments } from "react-icons/io5";
import { IoLinkSharp } from "react-icons/io5";
import ChatsView from "../../Components/ChatsView/ChatsView";
import { chatInfo } from "../../pruebasJson/chat";
import { ImAttachment } from "react-icons/im";
import { FaCamera } from "react-icons/fa6";
import { FaMicrophone } from "react-icons/fa6";
import { BiSolidSend } from "react-icons/bi";
import { IoDocumentTextSharp } from "react-icons/io5";
import moment from "moment";
import document2 from "../../images/document2.png";
import { ListChats } from "../../Apis/Chats/models/ListChats";
import { CreateChat } from "../../Apis/Chats/models/CreateChat";
import { ContactInfo } from "../../Apis/Chats/models/ContactInfo";

const Chats = () => {
  const [message, setMessage] = useState({
    id: 0,
    sender: "",
    message: "",
    timestamp: "",
    outgoing: true,
    incoming: false,
  });
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [view, setView] = useState("Recent");
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [chatActive, setChatActive] = useState(false);
  const [mediaActive, setMediaActive] = useState("");
  const [chats, setChats] = useState(chatInfo.chat);
  const currentUserId = USER_ID();
  const { favoriteLenguaje } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const year = new Date().getFullYear();
  const images = [
    document2, document2, document2, document2
  ]


  const getUserInfo = async () => {
    const classRef = new Get();
    await classRef.List({ MembersId: currentUserId });
    let result = classRef.ResponseBody;
  };

  const getAccountInfo = async () => {
    try {
      dispatch(showLoading());
      const classRef = new GetByMember();
      await classRef.List({ MembersId: currentUserId });
      let result = classRef.ResponseBody;
      dispatch(hideLoading());
    } catch (error) {
      console.log("Error", error);
    }
  };

  const handleWriteMessage = (e) => {
    setMessage({
      id: chats.length + 1,
      sender: "Juan",
      message: e.target.value,
      timestamp: moment().format("YYYY-MM-DDTHH:mm:ss"),
      outgoing: true,
      incoming: false,
    });
  };

  const sendMessage = () => {
    setChats([...chats, message]);
    setMessage({
      id: 0,
      sender: "",
      message: "",
      timestamp: "",
      outgoing: true,
      incoming: false,
    });
  }

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      sendMessage();
    }
  };

  const getListChats = async () => {
    const classRef = new ListChats();
    await classRef.List({
      AccountId: currentUserId,
      Page:null,
      PageSize:null,
    });
    let result = classRef.ResponseBody;
    console.log("result", result);
  }

  const createNewChat = async () => {
    const classRef = new CreateChat();
    await classRef.List({
      "Title": "Test Imbox",
      "Description": "DM",
      "MembersId": [currentUserId, 5]
    });
    let result = classRef.ResponseBody;
    console.log("result", result);
  }

  const getContactInfo = async () => {
    const classRef = new ContactInfo();
    await classRef.List({
      AccountId: currentUserId,
      ContactAccountId: 5
    });
    let result = classRef.ResponseBody;
    console.log("result", result);
  }

  useEffect(() => {
    createNewChat();
    getContactInfo();
    getListChats()
    getUserInfo();
    getAccountInfo();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Backdrop show={showBackdrop} onClick={() => setShowBackdrop(false)} />
      <div className="chat-principal-container">
        {view === "Recent" && (
          <div className="chat-view">
            <div className="chat-header">
              <div className="chat-title-container pt-4">
                <div className="chat-title mt-5">{dataInfo[favoriteLenguaje][3]?.communications}</div>
              </div>
              <div className="chat-greenline-container">
                <div className="chat-greenline"></div>
              </div>
            </div>
            <div className="chat-body">
              <div className="row w-100 p-2">
                <div className="col-12 col-md-6 col-lg-3">
                  <div className="input-group">
                    <input type="text" className="form-control form-control-md" placeholder="Search" />
                  </div>
                </div>
                <div className="col-12 text-center">
                  <div className="chat-blue-subtitle my-4">Recent Communications</div>
                </div>
                <div className="col-12 text-center">
                  <div className="chat-blue-subtitle2 mt-2">Today</div>
                </div>
              </div>
              <div className="row w-100 d-flex justify-content-center align-items-center flex-wrap">
                <ComunicationCard
                  onClick={() => setView("Profile")}
                />
                <ComunicationCard
                  onClick={() => setView("Profile")}
                />
                <ComunicationCard />
                <div className="col-12 text-center my-4">
                  <div className="chat-blue-subtitle2 mt-2" style={{ color: "#606061" }}>Yesterday</div>
                </div>
                <ComunicationCard
                  onClick={() => setView("Profile")}
                />
                <ComunicationCard
                  onClick={() => setView("Profile")}
                />
                <ComunicationCard
                  onClick={() => setView("Profile")}
                />
                <ComunicationCard
                  onClick={() => setView("Profile")}
                />
                <ComunicationCard
                  onClick={() => setView("Profile")}
                />
                <ComunicationCard
                  onClick={() => setView("Profile")}
                />
              </div>
            </div>
          </div>
        )}
        {view === "Profile" && (
          <div className={`chat-view col-12 ${!isMobile && "mt-4"}`}>
            <div className="principal-profile-container row w-100">
            <div className={`col-12 col-md-${chatActive ? "4" : "12"} d-flex flex-column justify-content-center align-items-center ${chatActive && isMobile ? "d-none" : ""}`} 
      style={{ backgroundColor: `${chatActive ? "#F5F6F8" : "#FFFFFF"}`, borderRadius: "20px" }}>
                <div className="chat-header">
                  <div className="chat-title-container pt-4">
                    <div className="chat-title mt-5">{dataInfo[favoriteLenguaje][3]?.profile}</div>
                  </div>
                  <div className="chat-greenline-container">
                    <div className="chat-greenline"></div>
                  </div>
                </div>
                <div className="chat-body mt-5" style={{ width: "100%", height: "auto", minHeight: "350px" }}>
                  <div className="row w-100 p-2 d-flex flex-column justify-content-center align-items-center">
                    <div className={`col-12 col-md-${chatActive ? "12" : "6"} profile-container`}>
                      <div className="profile-image-container">
                        <img src={profile} alt="Profile" />
                      </div>
                      <div className="profile-content">
                        <span className="profile-name mt-1">Marion Nash Smith</span>
                        <span className="profile-email mt-1">marion@nash.com</span>
                        <span className="profile-phone mt-2">+1 (555) 555-5555</span>
                      </div>
                      <div className="mb-2 mt-2" style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div style={{ width: "240px", height: "1px", backgroundColor: "#E0E6F3" }}></div>
                      </div>
                      <div className="d-flex justify-content-center align-items-center profile-buttons gap-2">
                        <div className="button-document">
                          <img src={document} alt="Document" />
                        </div>
                        <div className="button-chat" style={{ cursor: "pointer" }}>
                          <img src={chatProfile} alt="Chat" onClick={() => (
                            setChatActive(!chatActive)
                          )} />
                        </div>
                      </div>
                    </div>
                    <div className={`col-12 col-md-${chatActive ? "12" : "6"} d-flex justify-content-center align-items-center p-0 mt-1 mb-1`}>
                      <div style={{ width: "100%", height: "1px", backgroundColor: "#06B576" }}></div>
                    </div>
                    <div className={`col-12 col-md-${chatActive ? "12" : "6"} d-flex flex-column justify-content-center align-items-center entity-chat-container gap-1`}>
                      <span className="card-chat-title">Entity</span>
                      <span className="card-chat-subtitle">SoftCorp LLC</span>
                    </div>
                    <div className={`col-12 col-md-${chatActive ? "12" : "6"} d-flex justify-content-center align-items-center p-0 mt-1 mb-1`}>
                      <div style={{ width: "100%", height: "1px", backgroundColor: "#06B576" }}></div>
                    </div>
                    <div className={`col-12 col-md-${chatActive ? "12" : "6"} d-flex flex-column justify-content-center align-items-center entity-chat-container gap-1 pt-3 pb-3`}>
                      <span className="card-chat-title">Address</span>
                      <span className="card-chat-subtitle">1959 NE 153 ST GL (Suite)</span>
                      <span className="card-chat-subtitle">North Miami Florida</span>
                      <span className="card-chat-subtitle">33162 – 6017</span>
                    </div>
                  </div>
                </div>
              </div>
              {chatActive && (
                <div className="col-12 col-md-8 px-2">
                  <div className="card border-0">
                    <div className="card-header d-flex justify-content-between align-items-center border-0" style={{ backgroundColor: "#F0F0F0" }}>
                      <div className="card-header-title">
                        <div className="img-card-profile">
                          <img src={profile} alt="Profile" />
                          <div className="active-circle"></div>
                        </div>
                        <div className="subtitle-blue-20px">Marion Nash Smith</div>
                      </div>
                      <div className="card-header-buttons d-flex justify-content-end align-items-center gap-2">
                        <div className="header-media media-title" style={{ color: `${mediaActive === "media" ? "#383E45" : "rgba(56, 62, 69, 0.50)"}` }} onClick={() => {
                          if (mediaActive === "media") {
                            setMediaActive("");
                          } else {
                            setMediaActive("media");
                          }
                        }}>
                          <div className="header-circle-container" style={{ backgroundColor: `${mediaActive === "media" ? "#0572D2" : "#E1E1E1"}`, color: `${mediaActive === "media" ? "#fff" : "#929292"}` }}>
                            <FaImages />
                          </div>
                         {!isMobile && "Media"}
                        </div>
                        <div className="header-media media-title" style={{ color: `${mediaActive === "documents" ? "#383E45" : "rgba(56, 62, 69, 0.50)"}` }} onClick={() => {
                          if (mediaActive === "documents") {
                            setMediaActive("");
                          } else {
                            setMediaActive("documents");
                          }
                        }}>
                          <div className="header-circle-container" style={{ backgroundColor: `${mediaActive === "documents" ? "#0572D2" : "#E1E1E1"}`, color: `${mediaActive === "documents" ? "#fff" : "#929292"}` }}>
                            <IoDocuments />
                          </div>
                          {!isMobile && "Documents"}
                        </div>
                        <div className="header-media media-title" onClick={() => {
                          if (mediaActive === "links") {
                            setMediaActive("");
                          } else {
                            setMediaActive("links");
                          }
                        }} style={{ color: `${mediaActive === "links" ? "#383E45" : "rgba(56, 62, 69, 0.50)"}` }}>
                          <div className="header-circle-container" style={{ backgroundColor: `${mediaActive === "links" ? "#0572D2" : "#E1E1E1"}`, color: `${mediaActive === "links" ? "#fff" : "#929292"}` }}>
                            <IoLinkSharp />
                          </div>
                          {!isMobile && "Links"}
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className={`row ${isMobile && "p-0"}`}>
                        <div className={`col-${mediaActive ? (isMobile ? " d-none" : "9") : "12"} ${isMobile && "p-0"}`}>
                          <div className="chat-room-container">
                            <ChatsView
                              chats={chats}
                            />
                          </div>
                          <div className="col-12 d-flex justify-content-center align-items-center">
                            <div className="chat-room-writer d-flex justify-content-center align-items-center gap-4">
                              <ImAttachment size={25} />
                              <div className="input-group" style={{ width: "80%" }}>
                                <input type="text" className="form-control form-control-md" placeholder="Send a message"
                                  value={message.message}
                                  onChange={handleWriteMessage}
                                  onKeyDown={handleKeyPress}
                                />
                                <button className="btn btn-primary" type="button"
                                  onClick={() => {
                                    sendMessage();
                                    console.log("Chats", chats);
                                  }}>
                                  <BiSolidSend />
                                </button>
                              </div>
                              <FaCamera size={25} color="#0572D2" />
                              <FaMicrophone size={25} color="#0572D2" />
                            </div>
                          </div>
                        </div>
                        {mediaActive === "media" && (
                          <div className={`col-${isMobile ? "12" : "3"} p-0`}>
                            <div className="document-chat-container">
                            {images.map((image, index) => (
                              <div key={index} className="card-media-container">
                                <img src={image} alt="Document" />
                              </div>
                            ))}
                            </div>
                          </div>
                        )}
                        {mediaActive === "documents" && (
                          <div className={`col-${isMobile ? "12" : "3"} p-0`}>
                            <div className="document-chat-container">
                            {images.map((index) => (
                              <div key={index} className="card-document-container d-flex justify-content-between align-items-center p-1">
                                <div className="mx-2">
                                <IoDocumentTextSharp size={30} color="#0572D2" />
                                </div>
                                <div>
                                  <span>Document.doc</span>
                                </div>
                                <div className="d-flex flex-column align-items-end gap-1">
                                  <span className="documment-date">{moment().format("DD/MM/YYYY")}</span>
                                  <span className="document-time">10 MB</span>
                                </div>
                              </div>
                            ))}
                            </div>
                          </div>
                        )}
                        {mediaActive === "links" && (
                          <div className={`col-${isMobile ? "12" : "3"} p-0`}>
                             <div className="document-chat-container">
                            {images.map((index) => (
                              <div key={index} className="card-document-container d-flex justify-content-between align-items-center p-1">
                                <div className="mx-2">
                                <IoDocumentTextSharp size={30} color="#0572D2" />
                                </div>
                                <div>
                                  <span>Document.doc</span>
                                </div>
                                <div className="d-flex flex-column align-items-end gap-1">
                                  <span className="documment-date">{moment().format("DD/MM/YYYY")}</span>
                                  <span className="document-time">10 MB</span>
                                </div>
                              </div>
                            ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        <div className="d-footer">© Copyright {year} NDABee.</div>
      </div>
    </>
  );
};

export default Chats;
